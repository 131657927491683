import { Injectable } from '@angular/core';


@Injectable({
	  providedIn: "root"
})
export class Util {
	
	getBaseUrl() {
	      var re = new RegExp(/^.*\//);
	      var baseUrl = re.exec(window.location.href);
	      console.log("Base Url : " + baseUrl[0]);
	     // return  baseUrl[0];
	       var  pgUrl = window.location.origin;
	        var link = pgUrl+'/csaEP/';
	        console.log("link : " + link);    
	        if(window.location.port == '4200')
	        	 return "http://localhost:8070/csaEP/";
	        else
	        	return link;
//	      // Un comment below single line For running in 4200 server
//		  return "http://localhost:8070/csaEP/";
//		   // Comment below single line For running in 4200 server
//	     //return link; 
	  }
	  
}