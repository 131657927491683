import { Component, OnInit } from '@angular/core';
import { MeterEntryService } from 'src/app/service/fleet-service/meter-entry.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SerialMeterRead } from 'src/app/models/SerialMeterRead';
import { Util } from 'src/app/app.util';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-meter-entry',
  templateUrl: './meter-entry.component.html',
  styleUrls: ['./meter-entry.component.css']
})
export class MeterEntryComponent implements OnInit {

  objSourceTypes: any = { "CANON": "CANON", "DEALER": "DEALER" };
  source: string = '';

  smrList: SerialMeterRead[];

  serialList: any = [];
  srlNumber: string = '';
  custName: string = '';
  srlPageNum = 1;
  srlRecPerPage = 10;
  srlTotalRecords = 0;
  srlLastPage = 0;
  arrSrlPgLinks: Array<number>;

  custList: any = [];
  custPageNum = 1;
  custRecPerPage = 10;
  custTotalRecords = 0;
  custLastPage = 0;
  arrCustPgLinks: Array<number>;


  srlLkpMsgCls: string = '';
  srlLkpMsg: string = '';

  custLkpMsgCls: string = '';
  custLkpMsg: string = '';


  dealerName: string = '';

  bSubmitErr: boolean = false;
  bSubmitInProgress: boolean = false;
  bSubmitConfirmed: boolean = false;
  msg: string = '';
  msgClass: any = [];

  readsTemplateFile: File = null;
  readsTemplateFileName: File;
  arrReadsTemplateItemList: any[] = [];
  readsUpldMsg: string = '';
  readsUpldMsgCls: any = [];;

  // file
  fileToUpload: File = null;
  fileMsg = '';
  commonErrMsg = '';
  warningFileMsg = '';
  desc: string = '';
  filename: File;

  bSchDateWarning:boolean=false;

  userRoles = {};
  
  constructor(private router: Router, private route: ActivatedRoute, private util: Util, private meterEntryService: MeterEntryService,private loginAuthenticationService: LoginAuthenticationService) { }

  ngOnInit() {
    this.loginAuthenticationService.getUserRoles().subscribe(data => {
      this.userRoles = data;
      if (this.userRoles["epSRVAccess"] ||  this.userRoles["epFLTAccess"]){
         // has access
      }else{
         this.router.navigate(['dashboard']);
      }
    });

    this.smrList = [];
    let isAdmin = sessionStorage.getItem('Admin');
    if (isAdmin && isAdmin == 'Y') {
      this.router.navigate(['fleet']);
    }

    this.route.queryParams.subscribe(params => {
      let src: string = "";
      let srl: string = "";
      src = params['src'];
      srl = params['srl'];
      if (src && src.trim().length > 0) {
        src = src.trim();
        src = src.toUpperCase();
        this.source = this.objSourceTypes[src];
      } else {
        console.log("Source  is null. Sending user to Fleet Page");
        this.router.navigate(['fleet']);
      }
      if (srl && srl.trim().length > 0) {
        srl = srl.trim();
        srl = srl.toUpperCase();
        this.srlNumber = srl;
        this.addSerialsOnLoad();
      }

    });

    this.getDealerName();

  }

  getSerialList(pageNum: any, mdl: string, event: any) {
    this.serialList = [];
    this.srlLkpMsg = 'Loading Serials.Please wait.';
    this.srlLkpMsgCls = 'success';
    event.preventDefault();
    this.srlPageNum = pageNum;
    this.srlNumber = this.srlNumber.toUpperCase();
    this.custName = this.custName.toUpperCase();
    if(pageNum==1){
      this.arrSrlPgLinks =[];
      this.srlTotalRecords=0;
    }

    if (mdl == 'S') {
       this.srlNumber=this.srlNumber.trim();
    } 
    /*else if (mdl == 'C') {
      //  Added new Modal for Customer 
      this.srlNumber = '';
      this.custName=this.custName.trim();
    }*/
  
    let srlParam = {
      "srlNumber": this.srlNumber, "source": this.source, "custName": "",
      "srlPageNum": this.srlPageNum, "srlRecPerPage": this.srlRecPerPage
    };
    
    if (mdl == 'S' && this.srlNumber.trim().length < 1) {
      this.srlLkpMsg = 'Please enter Serial Number';
      this.srlLkpMsgCls = 'error';
      return;
    }
     /*else if (mdl == 'C' && this.custName.trim().length < 1) {
       // Added new modal for customer
      this.srlLkpMsg = 'Please enter Customer Name';
      this.srlLkpMsgCls = 'error';
      return;
    }*/

    this.meterEntryService.getSerialList(srlParam).subscribe(data => {
      this.serialList = data.data;
      this.srlTotalRecords = data.recCount;
      this.srlLastPage = (data.recCount % 10 == 0) ? (data.recCount / 10) : (parseInt(("" + data.recCount / 10)) + 1);
      this.arrSrlPgLinks = new Array(this.srlLastPage);


      if (data.errFlag && data.errFlag == 'E') {
        this.srlLkpMsg = 'Error Occurred while getting Serials. Please try again.';
        this.srlLkpMsgCls = 'error';
      } else {
        this.srlLkpMsg = '';
        this.srlLkpMsgCls = '';
      }
    });

  }

  addSerialsOnLoad(){

    let srlParam = {
      "srlNumber": this.srlNumber, "source": this.source, "custName": '',
      "srlPageNum":1, "srlRecPerPage": 10
    };

    this.meterEntryService.getSerialList(srlParam).subscribe(data => {
      let arrSrls=[];
          arrSrls = data.data;
       if(arrSrls&& arrSrls.length>0){
         for(let j=0;j<arrSrls.length;j++){
           this.addSerial(arrSrls[j],true);
         }
     }

      if (data.errFlag && data.errFlag == 'E') {
        this.srlLkpMsg = 'Error Occurred while getting Serials. Please try again.';
        this.srlLkpMsgCls = 'error';
      } else {
        this.srlLkpMsg = '';
        this.srlLkpMsgCls = '';
      }
    });
  }


  deleteSerial(idx) {
    if (this.smrList.length > 0) {
      let tmpSrl = this.smrList[idx];
      this.smrList.splice(idx, 1);
    }
  }



  addSerial(srl, bAdd) {
    this.bSubmitConfirmed=false;
    let bSrlExist: boolean = false;
    let srlIdx: number;

    for (let k = 0; k < this.smrList.length; k++) {
      if (srl.serialNumber == this.smrList[k].serialNumber) {
        srlIdx = k;
        bSrlExist = true;
      }
    }

    if (bAdd) {
        let smr = new SerialMeterRead();
        smr.serialNumber = srl.serialNumber;
        smr.model = srl.model;
        smr.customerName = srl.customerName;
        smr.source=this.source;
        smr.scheduleDate=srl.scheduleDate; 
        smr.address = srl.address;
        smr.bwRead = srl.bwRead;
        smr.totalRead = srl.totalRead;
        smr.clrFlag=srl.clrFlag;
        if(smr.clrFlag!="Y"){
          smr.bwRead=null;
        }
        if (srl.readDate) {
          smr.readDate = new Date(srl.readDate);
        }
        if (bSrlExist) {
          this.smrList[srlIdx]=smr;
        }else{
          this.smrList.push(smr);
        }
     
    } else {
      if (bSrlExist) {
        this.smrList.splice(srlIdx, 1);
      }
    }
  }

  checkSrl(srl) {
    let bSrlExist: boolean = false;
    for (let k = 0; k < this.smrList.length; k++) {
      if (srl.serialNumber == this.smrList[k].serialNumber) {
        bSrlExist = true;
      }
    }
    return bSrlExist;
  }

  checkCust(cust) {
    let bSrlExist: boolean = false;
    for (let k = 0; k < this.smrList.length; k++) {
      if (cust.installAddress == this.smrList[k].address) {
        bSrlExist = true;
      }
    }
    return bSrlExist; 
    
  }



  getCustomerList(pageNum: any, mdl: string, event: any) {
    this.custList = [];
    this.custLkpMsg = 'Loading Customer.Please wait.';
    this.custLkpMsgCls = 'success';
    event.preventDefault();
    this.custPageNum = pageNum;
    this.custName = this.custName.toUpperCase();
    if(pageNum==1){
      this.arrCustPgLinks =[];
      this.custTotalRecords=0;
    }
    this.custName=this.custName.trim();
  
    let custParam = {
       "source": this.source, "custName": this.custName,
      "custPageNum": this.custPageNum, "custRecPerPage": this.custRecPerPage
    };
    
    if ( this.custName.trim().length < 1) {
      this.custLkpMsg = 'Please enter Customer Name';
      this.custLkpMsgCls = 'error';
      return;
    }

    this.meterEntryService.getCustomerList(custParam).subscribe(data => {
      this.custList = data.data;
      this.custTotalRecords = data.recCount;
      this.custLastPage = (data.recCount % 10 == 0) ? (data.recCount / 10) : (parseInt(("" + data.recCount / 10)) + 1);
      this.arrCustPgLinks = new Array(this.custLastPage);


      if (data.errFlag && data.errFlag == 'E') {
        this.custLkpMsg = 'Error Occurred while getting Customer. Please try again.';
        this.custLkpMsgCls = 'error';
      } else {
        this.custLkpMsg = '';
        this.custLkpMsgCls = '';
      }
    });

  }

  addDeleteCustSrls(cust,bAddDelete){

    console.log("bAddDelete:"+bAddDelete+" , Cust : "+cust.customerName +" , Inst Addr : "+ cust.installAddress);

    let srlParam = {
      "srlNumber": "", "source": this.source, "custName": cust.customerName,"installAddress":cust.installAddress,
      "srlPageNum": this.srlPageNum, "srlRecPerPage": 10000
    };
    
    this.meterEntryService.getSerialList(srlParam).subscribe(data => {
      let arrSrls=[];
          arrSrls = data.data;
       if(arrSrls&& arrSrls.length>0){
         for(let j=0;j<arrSrls.length;j++){
           this.addSerial(arrSrls[j],bAddDelete);
         }
       }
    });

  }

  getDealerName() {
    let dnParams = {};
    this.meterEntryService.getDealerName(dnParams).subscribe(data => this.dealerName = data.dealerName);
  }

  exportToTemplate() {
    let srls:string='';
    for (let i = 0; i < this.smrList.length; i++) {
      let smr = this.smrList[i];
      srls=(srls.length>0)?srls+","+smr.serialNumber:smr.serialNumber;
    }
    var link = this.util.getBaseUrl() + "meterentry/ett?srls="+srls;
    window.open(link, "_blank");
  }

  downloadMeterReadsTemplate() {
    var link = this.util.getBaseUrl() + "meterentry/dmrt";
    window.open(link, "_blank");
  }


  uploadReadsTemplate() {
    this.readsUpldMsg = '';
    this.readsUpldMsgCls = [];
    this.fileMsg = "";
    this.commonErrMsg = "";
    this.warningFileMsg = "";
    this.arrReadsTemplateItemList = [];
    if (this.readsTemplateFile != null) {
      this.readsUpldMsg ="Validating Serials. Please wait.";
      this.readsUpldMsgCls=["success"];

      this.meterEntryService.uploadReadsTemplate(this.readsTemplateFile, this.source).subscribe(data => {
        let arrRds= [];
        arrRds = data.data;
        if (arrRds.length > 0) {
          let bInvalidSrl: boolean = false;
          for (let j = 0; j < arrRds.length; j++) {
            let errFlg = arrRds[j].errFlag;
            let smrObj = new SerialMeterRead();
            smrObj = arrRds[j];

            if (errFlg == "S") {
              this.addSerial(smrObj, true);
            } else {
              bInvalidSrl = true;
              this.arrReadsTemplateItemList.push(smrObj);
            }
           }
           
           if(bInvalidSrl){
            this.readsUpldMsg ="Below serials are Invalid. Please correct and upload.";
            this.readsUpldMsgCls=["error"];
           }else{
            this.readsUpldMsg ="Upload Complete.";
            this.readsUpldMsgCls=["success"];
      
           }
          this.readsTemplateFile = null;
          this.readsTemplateFileName = null;
        }else{
          this.readsUpldMsg ="No Serials found. Please check the template and upload.";
          this.readsUpldMsgCls=["error"];
        }
      },
        (error) => {
          this.commonErrMsg = "";
          console.log("Error: " + error);
          this.commonErrMsg = error;
        });;
    }
    else {
      this.warningFileMsg = "Please Upload the file and click Submit";
    }
  }

  readsTemplateFileChange(files: FileList) {
    this.readsTemplateFile = files.item(0);
  }

  clearUpload() {
    this.readsTemplateFile = null;
    this.readsTemplateFileName = null;
    this.arrReadsTemplateItemList = [];
    this.readsUpldMsg = '';
    this.readsUpldMsgCls = [];;

  }


  formatDate(d1: any): string {
    let sd: string = '';
    let FN = "[formatDate]";
    try {
      if (d1) {
        sd = (d1.getUTCMonth() + 1) + "/" + (d1.getUTCDate()) + "/" + d1.getFullYear();
      }
    } catch (error) {
      console.log(FN + error);
    }

    return sd;
  }

  clearAll(){
    this.smrList=[];
    this.srlNumber='';
    this.custName='';
  }

  checkScheduleDate(smr: SerialMeterRead): boolean {

    let scheduleDateWarning = false;

    try {

      if (this.source == 'CSA' && smr.scheduleDate && smr.readDate) { 
        var sd: any = new Date(smr.scheduleDate);
        let td: any = new Date(smr.readDate);
        let daysDiff = parseInt("" + ((sd - td) / (1000 * 60 * 60 * 24)), 10);
        if (daysDiff > 5) {
          scheduleDateWarning = true;
          this.bSchDateWarning=true;
        }

        
      }

    } catch (error) {
      scheduleDateWarning = false;
    }
    return scheduleDateWarning;
  }


  submitMeterReads(sFlg) {
    let bInvalid: boolean = false;
    let errMsg: string = '';
    this.bSubmitErr = false;
    this.bSchDateWarning=false; 
    this.msg = "";


    for (let i = 0; i < this.smrList.length; i++) {
      let smr = this.smrList[i];
      if(this.source=='CSA'){
        smr.scheduleDateWarning=this.checkScheduleDate(smr);
      }
      let bSrlErr: boolean = false;
      let bSrlErrMsg: string = '';
      if (smr.clrFlag=='Y' && (!smr.bwRead || (smr.bwRead && smr.bwRead < 1))) {
        bSrlErrMsg = " BW Read";;
        bInvalid = true;
        this.bSubmitErr = true;
        bSrlErr = true;
      }

      if (!smr.totalRead || (smr.totalRead && smr.totalRead < 1)) {
        let comma = (bSrlErrMsg.length > 0) ? "," : "";
        bSrlErrMsg = bSrlErrMsg + comma + "Total Read";
        bInvalid = true;
        this.bSubmitErr = true;
        bSrlErr = true;
      }

      if (smr.readDate.length < 1) {
        let comma = (bSrlErrMsg.length > 0) ? "," : "";
        bSrlErrMsg = bSrlErrMsg + comma + "Read Date"
        bInvalid = true;
        this.bSubmitErr = true;
        bSrlErr = true;
      }
      if (bSrlErr) {
        errMsg += "\n  " + smr.serialNumber + ":" + bSrlErrMsg;
      }
    }
    if (!bInvalid) {

      if (sFlg == 'C') {
        this.msg = "Submitting Meter Reads. Please Wait.";
        this.msgClass = ['success'];

        let readsData = JSON.stringify(this.smrList);
        this.bSubmitInProgress = true;
        let tmpSrl:string='';
        if(this.smrList.length==1){
          tmpSrl=this.smrList[0].serialNumber;
        }
        this.meterEntryService.submitMeterReads(readsData).subscribe(data => {
          let arr: any = [];
          arr = data.data;
          let errFlag = "S";
          errMsg = "";
          for (let k = 0; k < arr.length; k++) {
            let objErr = arr[k];
            if (objErr.errFlag == "E") {
              errFlag = "E";
              let tmpMsg:string= "";
                tmpMsg= objErr.errMsg;
               let mArr:string[]=   tmpMsg.split("\\n");
                 tmpMsg="";
                for(let j=0;j<mArr.length;j++){
                  if(mArr[j])
                  tmpMsg=tmpMsg+" \n "+mArr[j];
                }
               
              errMsg += "\n " + objErr.serialNumber + ":" + tmpMsg;
            }else{
              for (let k = 0; k < this.smrList.length; k++) {
                if (objErr.serialNumber == this.smrList[k].serialNumber) {
                  this.smrList.splice(k, 1);
                }
              }
            }

          }
          if (errFlag == 'S') {
            this.msg = "Meter Reads Submitted.";
            this.msgClass = ['success'];
            this.bSubmitInProgress = false;
            this.bSubmitConfirmed = true;
            this.clearAll();
            $(".modal-backdrop").remove(); // modal backgrond fix
            this.router.navigate(['meterhistory'],{ queryParams: { sn:  tmpSrl}});
          } else {
            this.msg = errMsg;
            this.msgClass = ['error'];
            this.bSubmitInProgress = false;
            this.bSubmitConfirmed = false;
          }
        });

      } else {
        //console.log("Submit");
      }

    } else {
      this.msg = "Please Correct reads for below Serial Numbers." + errMsg;
      this.msgClass = ['error'];
      return;
    }

  }

}
