import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginAuthenticationService } from '../../service/common-service/login-authentication.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string ="";
  password: string ="";
  errorMessage='Invalid Credentials';
  invalidLogin=false;
  showModal = false;
  changePassword : string ="";
  resetUserName:  string ="";
  newPassword: string ="";
  confirmPassword:  string ="";
  messageColor:  string ="";
  message:  string ="";
  errorList: string ="";
  oldPassword:  string ="";
  userAuthToken: any;
  fUserName: string ="";
  fEmailId: string="";
  fPhone: string ="";
  fVendorCode: string="";
  forgotPwdMessageColor:  string ="";
  forgotPwdMessage:  string ="";
  newUserMessageColor: string ="";
  newUserMessage: string ="";
  fCompName: string ="";
  btnSubmitted=false;
  constructor(private router:Router,private loginAuthenticationService:LoginAuthenticationService, private titleService: Title) { }

  ngOnInit() {
     
    this.titleService.setTitle("E-Partners Login");
  //   this.loginAuthenticationService.checkAuthLoginService().subscribe
  //   (data =>{
  //     console.log("LoginComponent, checkAuthLoginService --> Response :" + data); 
  //     console.log("LoginComponent, checkAuthLoginService --> Authenticate :" + data.authenticated);
  //     if(data.authenticated == true)
  //     {
  //     console.log("LoginComponent checkAuthLoginService Authenticated")  
  //     this.invalidLogin=false;
  //     this.router.navigate(['dashboard'])
  //     }
  //  });
  
    this.loginAuthenticationService.getUserAuthToken().subscribe(data => {
      this.userAuthToken = data;
      console.log('userAuthToken : ', this.userAuthToken.authToken);

    });


  }

  userLogin()
  {
     this.loginAuthenticationService.executeJDBCAuthenticationService(this.username.trim(),this.password.trim(),this.userAuthToken.authToken).subscribe
     (data =>{
      console.log(data)
      console.log("LoginComponent userlogin Authenticate:"+data.authenticated);    
      if(data.authenticated == true)
      {
        this.errorMessage = '';
        this.invalidLogin=false;
        this.changePassword = data.pwdChangeFlag;
        console.log("changePassword Flag:"+this.changePassword); 
        if(data.pwdChangeFlag == 'Y')
        {           
            this.router.navigate(['login']);
           this.resetUserName = this.loginAuthenticationService.getAuthenticatedUser();
        }
        else
        this.router.navigate(['dashboard']);
      }
     else
     {
      this.errorMessage = '';
     this.invalidLogin=true;
     this.errorMessage=data.errorMessage;
     console.log("Error message:"+data.errorMessage);
     }
   },
    error=>{
      console.log(error);
      this.errorMessage = '';
      this.invalidLogin=true;
    }
     );
  } 

  reset() {
    let inputParams : any = { resetUserName: this.resetUserName, 
       newPassword : this.newPassword, oldPassword : this.oldPassword}
  console.log("Inside Reset");
  
    var mediumRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g");    
    var enoughRegex = new RegExp("(?=.{8,}).*", "g");
    this.errorList ="";
    var noError = false;
    if (false == enoughRegex.test(this.newPassword)) {
      this.messageColor  = "alert alert-danger";
      this.message = "Password should have minimum 8 characters.";  
    }else if(this.newPassword != this.confirmPassword){
      this.messageColor  = "alert alert-danger";
      this.message = "New password & Confirm password should be same.";
    } else if (mediumRegex.test(this.newPassword)) {  
      this.messageColor = "alert alert-success";
      this.message = "Strong password";
      noError = true;
    } else {
      this.messageColor  = "alert alert-danger";
      this.message = "Password should have 1 Uppercase, 1 Lowercase , 1 Number";  
    }

    if(noError)
    {
      this.message  ="";
      this.loginAuthenticationService.changePasswordService(inputParams).subscribe(data => {
        console.log("changePasswordService, reset --> Response :" + data); 
        if(data != null && data.statusFlag == 'S')
        {
          this.router.navigate(['dashboard']);
          
        }else
        {
          this.errorList = data.status;
          this.router.navigate(['/login']);
        }
            
      });
    }

}

forgotPassword() {
	
	var noError = false;
	this.btnSubmitted=false;
	this.clear();
	
	if(this.fUserName.length == 0){
		this.forgotPwdMessageColor  = "alert alert-danger";
      	this.forgotPwdMessage = "Please enter User Name";	
		noError = false;
	}else{
		noError = true;
	}
	
	if(noError)
    {
		let inputParams : any = { userName: this.fUserName}
		this.loginAuthenticationService.forgotPassword(inputParams).subscribe
	    (data =>{  
			if(data != null && data["status"] == 'S'){
				this.btnSubmitted=true; 
		     	this.forgotPwdMessageColor  = "alert alert-success";
      			this.forgotPwdMessage = "A temporary password has been sent to the email address associated with the account. <br>If you need additional assistance, please email ePartners_inquiry@cusa.canon.com.";	  
			}else if(data != null && data["status"] == 'F'){ 
		     	this.forgotPwdMessageColor  = "alert alert-warning";
      			this.forgotPwdMessage = "A Canon representative will contact you. <br>If you need immediate assistance, please email ePartners_inquiry@cusa.canon.com.";	  
			}else{
				this.forgotPwdMessageColor  = "alert alert-danger";
      			this.forgotPwdMessage = "We are unable to process the request at this time. <br>Please email ePartners_inquiry@cusa.canon.com for additional assistance.";	  
			}    
	      });
	}
  }

  createNewUser() {
		this.clear();
    var noError = false;
    this.btnSubmitted=false;
    var emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);  
      var phoneRegex = new RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/); 
    if(this.fUserName.length == 0 || this.fEmailId.length == 0 || this.fPhone.length == 0 || this.fCompName.length == 0){
      this.newUserMessageColor  = "alert alert-danger";
          this.newUserMessage = "Please enter Dealer Name,Company Name,Email,Phone and/or Vendor Code";	
      noError = false;
    }else  if (false == emailRegex.test(this.fEmailId)) {     
        this.newUserMessageColor= "";
        this.newUserMessage= "";
        this.newUserMessageColor  = "alert alert-danger";
        this.newUserMessage = "Invalid Email.Please enter valid Email.";  
        noError = false;
      }
      else if (false == phoneRegex.test(this.fPhone)) {
          this.newUserMessageColor  = "alert alert-danger";
          this.newUserMessage = "Invalid Phone Number.Please enter valid Phone Number.";  
          noError = false;
        }
        else
        noError = true;
    
    if(noError)
      {
      let inputParams : any = { userName: this.fUserName, 
         email : this.fEmailId,vendorCode: this.fVendorCode, 
         phone : this.fPhone,companyName:this.fCompName}
      this.loginAuthenticationService.createNewUser(inputParams).subscribe
        (data =>{
		this.btnSubmitted=true;  
        if(data != null && data["status"] == 'S'){
			 this.btnSubmitted=true; 
             this.newUserMessageColor  = "alert alert-success";
             this.newUserMessage = "Thank you for your request.  A Canon representative will provide the login credentials within 3-5 business days.  If you need immediate assistance, please email ePartners_inquiry@cusa.canon.com.";	  
        }else{
          this.newUserMessageColor  = "alert alert-danger";
              this.newUserMessage = "Please contact adminstrator.";	  
        }    
          });
    }
    
  }
	clear() {
		     this.forgotPwdMessageColor  = "";
      	this.forgotPwdMessage = "";         
        this.newUserMessageColor= "";
        this.newUserMessage= "";
      
	}
  clearModal()
  {
    this.forgotPwdMessageColor  = "";
    this.forgotPwdMessage = "";         
    this.newUserMessageColor= "";
    this.newUserMessage= "";
    this.fUserName= "";
    this.fEmailId= "";
    this.fPhone= "";
    this.fVendorCode= "";
    this.fCompName= "";
    this.btnSubmitted=false;
  }
}

