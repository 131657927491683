export class CreateServiceRequest {

    serialNumber: string;
    model: string;
    orgranization: string;
    dealerReferenceNumber: string;
    callType: string;
    problemType: string;
    problemDescription: string;
    problemCode: string;
    problemSummary: string;
    technicianNote: string;
    generalNote: string;

    constructor() {

        this.serialNumber = '';
        this.model = '';
        this.orgranization = '';
        this.dealerReferenceNumber = '';
        this.callType = '1-SERV CALL';
        this.problemType = '';
        this.problemDescription = '';
        this.problemCode = '';
        this.problemSummary = '';
        this.technicianNote = '';
        this.generalNote = '';

    }

}