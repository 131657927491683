import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { Util } from '../../app.util';

const httpOptions = {
    headers: new HttpHeaders({
        
    }).set('Content-Type', 'application/json')
  };

@Injectable({
    providedIn: 'root'
})
export class ServiceRequestService {


  defaultUrl = 'assets/ServiceCalls.json';

  
  defaultUrlNew : string;

  constructor(private http: HttpClient, private util : Util) {
    
      this.defaultUrlNew = this.util.getBaseUrl();	
  }

  getServiceDealerList(data): Observable<any> {
    //data = JSON.stringify(data);
    let initialUrl = this.defaultUrlNew+"gscds";
    //let initialUrl = this.defaultUrl;
    //let initialUrl = this.defaultUrl;
   //console.log("in getOrderCsaList."+initialUrl);
  // console.log('fleet dealer data',data);
   return this.http
   .post(initialUrl,data,httpOptions)
  //.get(initialUrl)
     .pipe(catchError(this.handleError("getServiceDealerList")))
     // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
     //.pipe(map(data => this.toColleciton2(data)));
 }

 
getServiceCsaList(data): Observable<any> {
    //data = JSON.stringify(data);
    let initialUrl = this.defaultUrlNew+"gsccs";
    //let initialUrl = this.defaultUrl;
  //console.log("in getOrderCsaList."+initialUrl);
  //console.log('csa data',data);
  return this.http
    //.get(initialUrl)
    .post(initialUrl,data,httpOptions)
    .pipe(catchError(this.handleError("getServiceCsaList")))
    // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
    //.pipe(map(data => this.toColleciton2(data)));
}




getServiceEquipmentDealerList(data): Observable<any> {
  //data = JSON.stringify(data);
  let initialUrl = this.defaultUrlNew+"gesd";
  //let initialUrl = this.defaultUrl;
  //let initialUrl = this.defaultUrl;
 //console.log("in getOrderCsaList."+initialUrl);
// console.log('fleet dealer data',data);
 return this.http
 .post(initialUrl,data,httpOptions)
//.get(initialUrl)
   .pipe(catchError(this.handleError("getServiceEquipmentDealerList")))
   // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
   //.pipe(map(data => this.toColleciton2(data)));
}


getServiceEquipmentCsaList(data): Observable<any> {
  //data = JSON.stringify(data);
  let initialUrl = this.defaultUrlNew+"gescd";
  //let initialUrl = this.defaultUrl;
//console.log("in getOrderCsaList."+initialUrl);
//console.log('csa data',data);
return this.http
  //.get(initialUrl)
  .post(initialUrl,data,httpOptions)
  .pipe(catchError(this.handleError("getServiceEquipmentCsaList")))
  // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
  //.pipe(map(data => this.toColleciton2(data)));
}


getServiceDetails(reqNumber): Observable<any> {
    //data = JSON.stringify(data);
    let initialUrl = this.defaultUrlNew+"getDebrief?reqNum="+reqNumber;
    //let initialUrl = this.defaultUrl;
  //console.log("in getOrderCsaList."+initialUrl);
  //console.log('csa data',data);
  return this.http
    //.get(initialUrl)
    .post(initialUrl,httpOptions)
    .pipe(catchError(this.handleError("getServiceDetails")))
    // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
    //.pipe(map(data => this.toColleciton2(data)));
  }

  getResolutionCode(): Observable<any> {
    //data = JSON.stringify(data);
    let initialUrl = this.defaultUrlNew+"getResolutionCode";
  //console.log("in getOrderCsaList."+initialUrl);
  //console.log('csa data',data);
  return this.http
    //.get(initialUrl)
    .post(initialUrl,httpOptions)
    .pipe(catchError(this.handleError("getResolutionCode")))
    // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
    //.pipe(map(data => this.toColleciton2(data)));
  }

  confirmDebriefInfo(data): Observable<any> {
    //data = JSON.stringify(data);
    let initialUrl = this.defaultUrlNew+"submitDebrief";
  //console.log("in getOrderCsaList."+initialUrl);
  //console.log('csa data',data);
  return this.http
    //.get(initialUrl)
    .post(initialUrl,data,httpOptions)
    .pipe(catchError(this.handleError("getResolutionCode")))
    // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
    //.pipe(map(data => this.toColleciton2(data)));
  }

  getServiceNotes(data): Observable<any> {
    //data = JSON.stringify(data);
    let initialUrl = this.defaultUrlNew+"gNotes";
    //let initialUrl = this.defaultUrl;
    //let initialUrl = this.defaultUrl;
   //console.log("in getOrderCsaList."+initialUrl);
  // console.log('fleet dealer data',data);
   return this.http
   .post(initialUrl,data,httpOptions)
  //.get(initialUrl)
     .pipe(catchError(this.handleError("getServiceNotes")))
     // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
     //.pipe(map(data => this.toColleciton2(data)));
  }
  


private handleError<T>(operation = "operation", result?: T) {
  return (error: any): Observable<T> => {
    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

csaServiceLabelsAdmin=["dealerName","serialNumber","model","status","canonRequest","dealerReference","creationDate","customerName","machineAddress","problemSummary"];
      
dealerServiceLabelsAdmin=["dealerName","serialNumber","model","status","canonRequest","dealerReference","creationDate","customerName","machineAddress","problemSummary"];

csaServiceLabels=["serialNumber","model","status","canonRequest","dealerReference","creationDate","customerName","machineAddress","problemSummary"];
      
dealerServiceLabels=["serialNumber","model","status","canonRequest","dealerReference","creationDate","customerName","machineAddress","problemSummary"];

camel2title(camelCase) {
    return camelCase
     // inject space before the upper case letters
     .replace(/([A-Z])/g, function(match) {
        return " " + match;
     })
     // replace first char with upper case
     .replace(/^./, function(match) {
       return match.toUpperCase();
     });
 }

//showLabel = true;

jsonToExcel(JSONData, outputFileName, ShowLabel) {
          
         //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
         var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
         var CSV = '';    
         //Set Report title in first row or line
         //CSV += ReportTitle + '\r\n\n';
         
         var isAdmin=	sessionStorage.getItem('Admin');
         var lbls;
         var custArr;
         var bCustom=false;
         if(outputFileName=="Canon Service"){  
           if(isAdmin=='Y'){
            lbls= this.csaServiceLabelsAdmin;
           }else{
             lbls= this.csaServiceLabels;
           }
             bCustom=true;  
         }else if (outputFileName=="Dealer Service"){ 
           if(isAdmin =='Y'){
            lbls = this.dealerServiceLabelsAdmin;
           } else {
             lbls = this.dealerServiceLabels;
           }
           
             bCustom=true;
         }else{
             lbls=arrData[0];
         }
         
         //This condition will generate the Label/Header
         if (ShowLabel) {
             var row = "";
             //This loop will extract the label from 1st index of on array
             if(bCustom){
                 for (var index in lbls) {
                     //Now convert each value to string and comma-seprated
                     var lbl=lbls[index];
                     lbl=this.camel2title(lbl);  
                     row += lbl + ',';
                 }
             }else{ 
                 for (var index in arrData[0]) {
                     //Now convert each value to string and comma-seprated
                     index=this.camel2title(index);
                     row += index + ',';
                 }
             }
             row = row.slice(0, -1);
             //append Label row with line break
             if(row.indexOf("Dealer Dba")>-1){
               row=row.replace("Dealer Dba","Dealer DBA");
             }
             if(row.indexOf("Rma")>-1){
                row=row.replace("Rma","Contains Return");
              }
              if(row.indexOf("Machine Address")>-1){
                row=row.replace("Machine Address","Install Address");
              } 
             CSV += row + '\r\n';
         }
         
         if(bCustom){
             
                 //1st loop is to extract each row
                 for (var i = 0; i < arrData.length; i++) {
                     var row = "";
                 //2nd loop will extract each column and convert it in string comma-seprated
                      var oData=arrData[i];
                     
                     for(const k in lbls){  
                         
                         //Skip "" when '=' is appended for preserving leading zeros
                         var arrDataVal = oData[lbls[k]];
                         //console.log(arrDataVal);
                         //check for null and assign "" string
                         if(null == arrDataVal)
                             arrDataVal = "";
                         if(!isNaN(arrDataVal))
                             arrDataVal = arrDataVal.toString();
                         if(arrDataVal.startsWith('=')){
                             row += arrDataVal + ',';
                             continue;
                         }
                          
                         row += '"' + arrDataVal + '",';
                      
                 }
                     row.slice(0, row.length - 1);
                     //add a line break after each row
                     CSV += row + '\r\n';
             }
             
         }else{
         //1st loop is to extract each row
         for (var i = 0; i < arrData.length; i++) {
             var row = "";
             //2nd loop will extract each column and convert it in string comma-seprated
             for (var index in arrData[i]) {
                 
                 //Skip "" when '=' is appended for preserving leading zeros
                 var arrDataVal = (arrData[i][index]);
                 //check for null and assign "" string
                 if(null == arrDataVal)
                     arrDataVal = "";
                 if(!isNaN(arrDataVal))
                     arrDataVal = arrDataVal.toString();
                 if(arrDataVal.startsWith('=')){
                     row += arrDataVal + ',';
                     continue;
                 }
                 row += '"' + arrDataVal + '",';
             }
             row.slice(0, row.length - 1);
             //add a line break after each row
             CSV += row + '\r\n';
         } 
         
         }
         if (CSV == '') {        
             alert("Invalid data");
             return;
         }
         
        //this trick will generate a temp "a" tag
         var link = document.createElement("a");    
         link.id="lnkDwnldLnk";

         //this part will append the anchor tag and remove it after automatic click
         document.body.appendChild(link);

         var csv = CSV;  
         var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;'}); 
         
         //Generate a file name
         var fileName =outputFileName;   
         //this will remove the blank-spaces from the title and replace it with an underscore
        // fileName += outputFileName.replace(/ /g," ");

         if (navigator.msSaveBlob) { // IE 10+
             navigator.msSaveBlob(blob, fileName + ".csv")
                 } else {
                     var link = document.createElement("a");
                     if (link.download !== undefined) { // feature detection
                         // Browsers that support HTML5 download attribute
                         var csvUrl = window.URL.createObjectURL(blob);
                         link.setAttribute("href", csvUrl);
                         link.setAttribute("download", fileName + ".csv");
                         link.style.display = "visibility:hidden";
                         document.body.appendChild(link);
                         link.click();
                         document.body.removeChild(link);
                     }           
                 }
     }






}
