import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { AcknowledgementService } from '../../service/fleet-service/acknowledgement.service';

declare var $: any;

@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.css']
})
export class AcknowledgementComponent implements OnInit {

  constructor(private route:ActivatedRoute, private loginAuthenticationService:LoginAuthenticationService, private titleService: Title, private acknowledgementService:AcknowledgementService ) {}

  setDealersCSS() {
    //alert("Hello"); 
    $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
    $(".site-logo").attr("src", "assets/img/ep-dealers-logo.png");
    $(".csa-page-header-title").hide();
    //$(".csa-page-header-title").show();
    $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
    $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
    $(".main-top-navbar").css("border-top", "1px solid #fff");
    $(".fa-ellipsis-v").css("color", "#396f96");
    $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #1b6d85", "background-color": "#7395ae", "color": "#fff" });

  
        $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");

        $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");

    $(".side-more-icons-wrapper").hover(() =>{
        $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
    }, () =>{
        $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
    });
    $(".side-menu-button-wrapper i").hover(() => {
        $(this).css({ "color": "rgb(38, 154, 188)", "border": "3px solid rgb(38, 154, 188)" });
    }, () =>{
        $(this).css({ "color": "#fff", "border": "3px solid #fff" });
    });
    $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
    // $(".group-switcher").css({
    //         "border-color": "#999",
    //         "background-color": "#999 !important",
    //         "pointer-events": "none"
    //     });
    //       $("#main-top-nav-switcher").css({ 
    //         "border-color": "#999",
    //         "background-color": "#999",

    //     });
    //   $("#main-menu-switcher").css({ 
    //         "border-color": "#999",
    //         "background-color": "#999",

    //     });
    $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
    //$(".main-csa-content-wrapper,.csa-page-header-title").show();
    $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
    $(".btn-primary").css({ "border-color": "#2aabd1", "background-color": "#2286a3", "color": "#fff" });
    $(".result-table tr td i").css({ "color": "#2aabd1", "border": "solid 1px #2aabd1" });
    $("html,body").css({ "background-color": "#ddd" });
    $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({ "color": "#fff" });
    $(".result-table tr th").css({ "background-color": "#7395ae" });

    $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
    $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");

    setTimeout(function(){ 
     
      $(".group-switcher").css({
        "border-color": "#999",
        "background-color": "#999 !important",
        "pointer-events": "none"
    });

    $("#main-top-nav-switcher").css({ 
      "border-color": "#999",
      "background-color": "#999",

  });
  $("#main-menu-switcher").css({ 
      "border-color": "#999",
      "background-color": "#999",

  });
  
  
  }, 200);

}

tableLoader = true;
acknowledgementList = [];
acknowledgementInfo = [];
selectedSortBy;
selectedSortOrder;
isAdmin : any;

setAcknowledge(ack) {

  this.acknowledgementInfo = ack; 
  //console.log('acknowledgementInfo..',this.acknowledgementInfo)


}

setSortOrder(sortBy,sortOrder) {
  
  this.selectedSortBy = sortBy;
  this.selectedSortOrder = sortOrder;
  this.setAckList();    


}


getAckParam () {
  

  return {
      
    "serialNumber":"",
    "pageNum": "1",
    "recPerPage":"3000",
    "sortBy": this.selectedSortBy,
    "sortOrder": this.selectedSortOrder 
      //"pageNum": this.pageNum,
      //"recPerPage":this.recPerPage,
     // "sortBy": this.selectedSortBy,
      //"sortOrder": this.selectedSortOrder
    }
    
  }
  
  setAckList() {
    this.getAcknowledgementList(this.getAckParam());
    
   }
submitAll = true;
confirmMessage;
   isSubmitAllActive(){
     if($('.ack-btn').length > 0){
      this.submitAll = false;
        } else {
          this.submitAll = true;
        }
   }

   submitAllAck(ackc) {

    console.log("acknowledgementList...",this.acknowledgementList);
    // let ackList = [];
    // ackList.push(ackc);

    this.acknowledgementService.confirmAcknowledgement(this.acknowledgementList).subscribe(data => {
      this.confirmMessage = data.result;
      console.log('this.confirmMessage',this.confirmMessage);
      this.setAckList();
    
    });


  }

  acknowledgeConfirm(ackc) {
    console.log("ackc",ackc);
    let ackList = [];
    ackList.push(ackc);

    this.acknowledgementService.confirmAcknowledgement(ackList).subscribe(data => {
      this.confirmMessage = data.result;
      console.log('this.confirmMessage',this.confirmMessage);
      this.setAckList();
    
    });


  }



getAcknowledgementList(params) { 

  this.tableLoader = true;
  this.acknowledgementService.getAcknowledgementList(params).subscribe(data => {
  this.acknowledgementList = data.data;
  this.tableLoader = false;

  console.log('acknowledgementList ',this.acknowledgementList);
 

});
}



  ngOnInit() {
    this.titleService.setTitle("Service Details All Acknowledgements");
    this.isAdmin =	sessionStorage.getItem('Admin');
    this.setDealersCSS();
    this.setAckList();
    

    
  }

}
