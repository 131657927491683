import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-csa-create-new',
  templateUrl: './service-csa-create-new.component.html',
  styleUrls: ['./service-csa-create-new.component.css']
})
export class ServiceCsaCreateNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
