import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { OrderDetailDealerService } from 'src/app/service/order-mgmt-service/order-detail-dealer.service';
import { Util } from 'src/app/app.util';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { DatePipe, formatDate } from '@angular/common';

declare var $: any;


@Component({
  selector: 'app-order-detail-dealer',
  templateUrl: './order-detail-dealer.component.html',
  styleUrls: ['./order-detail-dealer.component.css'],
  
})

export class OrderDetailDealerComponent implements OnInit {

  loggedUserName=''; 
  loggedUserFullName='';
  orderNum: number;
  totalconfigList:Number;
  totalcontactList:Number;
  totalshipmentList:Number;
  totalcompensationList:Number;
  totalinstallCallList:Number;
  totalnotesList:Number;
  totalAttachmentList:Number;

  selectedOrder: any[] = [];

  dealerOrderGsd: any[] = [];
  dealerOrderShipmentList: any[] = [];
  dealerOrderCompensationList: any[] = [];
  dealerOrderInstallCallList: any[] = [];
  dealerOrderContactList: any[] = [];
  dealerOrderNotesList: any[] = [];
  dealerOrderAttachmentsList: any[] = [];
  configTab: string ='orderList-tab-active configTabs';
  shipmentTab: string = '';
  compensationTab: string = '';
  installCallTab: string = '';
  contactsTab: string = '';
  notesTab:string ='';
  attachmentTab:string ='';
  message: string = '';
  id: any='';
  sub: any ='';
  subod: any;
  subcn: any;
  subhd: any;
  ord: any='';
  custNm: any='';
  subpo: any;
  refPoNum: any='';
  checkboxModel:string='';
  saveStatus: any;
  headerId: any;
  csaOrderDelHist: any;
  selectedUploadedOrder: any;
  fileToUpload: File = null;
  lineId: any;
  fileMsg:string;
  validateShipStatus:string;
  saveShipStatus:string;
  confirmShipStatus:string;
  reasonCode: string;
  comments: string;
  disableProp: any;
  shipBtnDisable: boolean;
  serializedDate:string;
  masterSelected:boolean;
  checklist:any;
  shipmentCheckedList:any;
  contactCheckedList: any;
  masterContactsSelected: boolean;
  masterShipmentSelected: boolean;
  defaultUrlNew: any;
  selectedOrderDate: string;
  scheduldedDelDate: string;
  latest_date: any;
  reasonCodeErrMsg: string;
  reasonCodeList: any;
  showReasonCodeErr: string;
  confirmedLines: any;
  showCheckboxErr: string;
  showContactErr: string;
  shipOrgId: any;
  dealerNm: any ='';
  errorMsg: any;
  shipmentErrorMsg: any;
  shipmentConfirmErrorMsg: any;
  minDate = new Date();
  checkDate =new Date("");
  selectedSchDate: Date;
  checkShipmentSchDt: any;
  parentId: any;
  selectedSortBy: any;
  selectedSortOrder: any;
  schdDtList: any[];
  schDtErrMsg: any;
  showSchDtErr: string;
  childId: any;
  isAdmin: string;
  warningFileMsg: string;
  commonErrMsg :string;
  desc: string;
  confirmChkErrList: any;
  checkDateErr: boolean;
  fileLineId: any;
  resMsgList: string;
  saveWarning: any;
  saveInfoMsg: any;
  delearCompFlag: any;
  commonFileErrMsg: string;
  subrdd: any;
  reqdd: any ='';
  commonDelErrMsg: string;
  commonGsdErrMsg: string;
   valFiletype: boolean;
  parentSchDate: string;
  copyShipmentValFlag: boolean;
  parentReasonCode: any;
  parentNotes: any;
  parentCustName: any;
  parentCustPhone: any;
  parentCustEmail: any;
  setConfigNum = true;
  setTotalPrice = true;
  setUnitPrice = true;
  setItemType = true;
  setQty = true;
  setServiceModel = true;
  setItemCode = true;
  setItemDesc = true;
  setLineNum =true;
  setRma =true;
  setSourcedBy =true;
setRetAct =true;
setDelHist =true;
setDelInvDest =true;
setCnaCode =true;
setCusaPo =true;
setPrAtt =true;
setState =true;
setZipCode =true;
setCity =true;
setAddress= true;

setFindFee =true;
setRemComp =true;
setInstFee =true;
setPricePlan =true;
setContType =true;
setBasePrice =true;
setBwAll =true;
setBwCpc =true;
setClrAll =true;
setClrCpc =true;
setBaseBillCycle =true;
setUsBillCycle =true;
setTerm =true;

setDPConName =true;
setDPConPhone =true;
setDPConEmail =true;
setDSConName =true;
setDSConPhone =true;
setDSConEmail =true;
setCPConName =true;
setCPConPhone =true; 
setCPConEmail =true;

setNotesId =true;
setTimestamp =true;
setComment =true;
setSource =true;
setCreatedBy =true;


setAttOrderNum=true;
setDocType=true;
setDocRef=true;
setFileName=true;
setFileDesc=true;
setDateTime=true;
setAttSource=true;
setDelete=true;
setBlkMtrRdSt =true;
setBlkMtrRdEd =true;
setClrMtrRdSt =true;
setClrMtrRdEd =true;
  setSelectAll =true;
  attachId: any;
  fileName: any;
  serialNumList: any[];
  serErrMsg: any;
  massApplySelected: boolean;
  parentRma: any;
  tableLoader = true; 
  readOnlyFlag:any='';
  disableSaveFlag: boolean;
  ohaddress: string = "";
  ohcontactname: string = "";
  ohcontactphone: string = "";
  ohcontactemail: string = "";
  ohtotal: number =0;
  ohcomptotal:   number =0;
  ohexpensetotal:  number =0;
  ohitemstotal:   number =0;
  newcomment: string='';
  saveNotesStatus: string='';
  submitNotesBtn: boolean =false;
  saveNotesErrorStatus: string ='';
  approvalId: any ='';
 
  orderNumber: any;
  dealerCsaObj: any;
  source: string= '';
  masterOrderLineSelected: boolean;
  orderLineCheckedList: any[] =[];
  conCatLineNumber: string= '';
  docTypebtn: boolean = false;
  requestId: string= '';
  searchInstall:  string= '';
  searchOrderLine:  string= '';
  searchOrderItem:  string= '';
  searchOrderServiceModel:  string= '';
  searchOrderDescription:  string= '';
  searchSerialNumber:  string= '';
  searchRequestNumber: string= '';
  orderLineNumberValue: string= '';
  checkBoxError: string;
  checkBoxErrorMsg: string= '';
  selectedDocType = '';
  selectedOrderLine: any[] = [];
  parentOrderLineId: any;
  searchOrderDesc: string;
  searchOrderSerNum: string;
  fileMainMsg: any;
  warningFileDocMsg: string;
  valFileSize: boolean;
  ordStatus: any;
  ohordDate: any;
  arrSchDtLnId:String[];

   rsnCdList:any[]=[];
                
  
  constructor(private activatedRoute: ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private orderDtlDealerService: OrderDetailDealerService,private util : Util,public datepipe: DatePipe, private titleService: Title) { }
  dealerOrderConfigList: any[] = [];

   ngOnInit(): void {  
    console.log("minDate:"+this.minDate);
    console.log("checkDate:"+this.checkDate);
    this.validateShipStatus == "";
    this.shipBtnDisable=false;
    this.isAdmin=	sessionStorage.getItem('Admin');
   
    this.getReasonCodesList();

    this.titleService.setTitle("Order Details");
    setTimeout(function(){ 
     
      $(".group-switcher").css({
        "border-color": "#999",
        "background-color": "#999 !important",
        "pointer-events": "none"
    });

    $("#main-top-nav-switcher").css({ 
      "border-color": "#999",
      "background-color": "#999",

  });
  $("#main-menu-switcher").css({ 
      "border-color": "#999",
      "background-color": "#999",

  });
  
  
  }, 200);
    this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
    this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();
    console.log("LoggedIn user: from Header"+this.loggedUserName);
    console.log("LoggedIn user Full Name: from Header"+this.loggedUserFullName);
   // $( "#validateShip" ).empty();
     this.sub = this.activatedRoute.queryParams.subscribe(params => {
       this.id = params['id'];
       this.ord =  params['od'];
       this.headerId = params['hd'];
       this.readOnlyFlag =  params['appflag'];  
       this.approvalId=  params['appId'];
       this.ordStatus= params['ordStatus'];
       this.ohordDate= params['ohordDate'];
       this.readOnlyFlag = JSON.parse(this.readOnlyFlag);   
       this.setTab('config');
       });
       this.subod = this.activatedRoute.params.subscribe(params => {
        this.dealerNm = params['dn'];
    });
    if(this.readOnlyFlag == true)
    {
     // alert("readonlyflag")
    //this.setDealersCSS();
    this.titleService.setTitle("Order Approvals");
    }
        /*this.subod = this.activatedRoute.queryParams.subscribe(params => {
         this.ord = params['od'];
         });
     
        this.subcn = this.activatedRoute.params.subscribe(params => {
           this.custNm = params['cn'];
           });
         this.subpo = this.activatedRoute.params.subscribe(params => {
             this.refPoNum = params['po'];
             });
         this.subhd = this.activatedRoute.params.subscribe(params => {
               this.headerId = params['hd'];
               });
            this.sub = this.activatedRoute.params.subscribe(params => {
                this.dealerNm = params['dn'];
            });
            this.sub = this.activatedRoute.params.subscribe(params => {
                this.readOnlyFlag = JSON.parse(params['rd']);
                this.setTab('config');
            });
       this.subrdd = this.activatedRoute.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.reqdd = params['rdd'];
      });*/
       console.log("ITT:"+this.id);
       console.log("REq Date:"+this.reqdd );
      

  this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
  this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();

  this.checkboxModel='Yes';
  let username = this.loggedUserName;  
  this.getOrderDtlHeader();
  }

  changeAttachmentsSelectAll() {
    if(this.setSelectAll) {
      this.setAttOrderNum=true;
      this.setDocType=true;
      this.setDocRef=true;
      this.setFileName=true;
      this.setFileDesc=true;
      this.setDateTime=true;
      this.setAttSource=true;
      this.setDelete=true;
    }else {

      this.setAttOrderNum=false;
      this.setDocType=false;
      this.setDocRef=false;
      this.setFileName=false;
      this.setFileDesc=false;
      this.setDateTime=false;
      this.setAttSource=false;
      this.setDelete=false;
    } 
  }
   changeConfigSelectAll() {
     if(this.setSelectAll) {
      this.setConfigNum = true;
      this.setTotalPrice = true;
      this.setUnitPrice = true;
      this.setItemType = true;
      this.setQty = true;
      this.setServiceModel = true;
      this.setItemCode = true;
      this.setItemDesc = true;
      this.setLineNum =true;
      this.setRma =true;
      this.setSourcedBy =true;
     }else {

      this.setConfigNum = false;
      this.setTotalPrice = false;
      this.setUnitPrice = false;
      this.setItemType = false;
      this.setQty = false;
      this.setServiceModel = false;
      this.setItemCode = false;
      this.setItemDesc = false;
      this.setLineNum =false;
      this.setRma =false;
      this.setSourcedBy =false;
     } 
   }
    

   changeShipmentSelectAll() {
    if(this.setSelectAll) {
      this.setRetAct  = true;
      this. setDelHist  = true;
      this.setDelInvDest  = true;
      this.setCnaCode = true;
      this.setCusaPo  = true;
      this.setPrAtt  = true;
      this.setAddress  = true;
      this.setState  = true;
      this.setZipCode  = true;
      this.setCity  = true;
     this.setItemCode = true;
     this.setItemDesc = true;    
     this.setRma =true;
     this.setBlkMtrRdSt =true;
     this.setBlkMtrRdEd =true;
     this.setClrMtrRdSt =true;
     this.setClrMtrRdEd =true;
    }else {

      this.setRetAct  = false;
      this. setDelHist  = false;
      this.setDelInvDest  = false;
      this.setCnaCode = false;
      this.setCusaPo  = false;
      this.setPrAtt  = false;
      this.setState  = false;
      this.setZipCode  = false;
      this.setCity  = false;
      this.setAddress  = false;
     this.setItemCode = false;
     this.setItemDesc = false;    
     this.setRma =false;
     this.setBlkMtrRdSt =false;
     this.setBlkMtrRdEd =false;
     this.setClrMtrRdSt =false;
     this.setClrMtrRdEd =false;
    } 
  }
  
  changeCompensationSelectAll() {
    if(this.setSelectAll) {
      this.setFindFee =true;
      this.setRemComp =true;
      this.setInstFee =true;
      this.setPricePlan =true;
      this.setContType =true;
      this.setBasePrice =true;
      this.setBwAll =true;
      this.setBwCpc =true;
      this.setClrAll =true;
      this.setClrCpc =true;
      this.setBaseBillCycle =true;
      this.setUsBillCycle =true;
      this.setTerm =true;
      this.setItemCode = true;
      this.setItemDesc = true;    
      this.setRma =true;
      this.setLineNum =true;
    }else {

      this.setFindFee =false;
      this.setRemComp =false;
      this.setInstFee =false;
      this.setPricePlan =false;
      this.setContType =false;
      this.setBasePrice =false;
      this.setBwAll =false;
      this.setBwCpc =false;
      this.setClrAll =false;
      this.setClrCpc =false;
      this.setBaseBillCycle =false;
      this.setUsBillCycle =false;
      this.setTerm =false;
      this.setItemCode = false;
      this.setItemDesc = false;    
      this.setRma =false;
      this.setLineNum =false;
    } 
  }

  changeContactsSelectAll() {
    if(this.setSelectAll) {
      this.setDPConName =true;
      this.setDPConPhone =true;
      this.setDPConEmail =true;
      this.setDSConName =true;
      this.setDSConPhone =true;
      this.setDSConEmail =true;
      this.setCPConName =true;
      this.setCPConPhone =true; 
      this.setCPConEmail =true;
      this.setItemCode = true;
      this.setItemDesc = true;    
      this.setRma =true;
      this.setLineNum =true;
    }else {

      this.setDPConName =false;
      this.setDPConPhone =false;
      this.setDPConEmail =false;
      this.setDSConName =false;
      this.setDSConPhone =false;
      this.setDSConEmail =false;
      this.setCPConName =false;
      this.setCPConPhone =false; 
      this.setCPConEmail =false;
      this.setItemCode = false;
      this.setItemDesc = false;    
      this.setRma =false;
      this.setLineNum =false;
    }
  }

  changeNotesSelectAll() {
    if(this.setSelectAll) {
      this.setNotesId=true;
      this.setTimestamp =true;
      this.setComment =true;
      this.setSource = true;
      this.setCreatedBy = true;   
      
    }else {
      this.setNotesId=false;
      this.setTimestamp =false;
      this.setComment =false;
      this.setSource = false;
      this.setCreatedBy = false; 
    } 
  }
  ngOnDestroy() {
    // this.sub.unsubscribe();
    // this.subod.unsubscribe();
    // this.subcn.unsubscribe();
    // this.subpo.unsubscribe();
    // this.subhd.unsubscribe();
  }
  
  onBack(): void {
    // this._router.navigate(['product']);
  }
 
       pageLoad() {
    $("#page-loader-overlay")
        .delay(800)
        .fadeOut();
    $("#delay-loader")
        .delay(1000)
        .fadeOut();
       
       }

changeSchDate(event,shipment)
{
  //alert("Change Schedule Date" +shipment.scheduldedDelDate);
  this.commonErrMsg = "";
  let sDate:any;
 if(shipment.scheduldedDelDate == null)
 {
  console.log("Empty shipment scheduled date");
 }
 else{
  try {
    sDate = this.datepipe.transform(shipment.scheduldedDelDate, 'MM/dd/yyyy');
    if(new Date(sDate) < this.minDate)
    {
     // alert("Please select only future date");
      this.commonErrMsg ="Please select date greater than or equal to today's date";
      this.checkDateErr = false;
    }
  } catch (error) {
    this.commonErrMsg = "Please select valid date";
  }
}
}
   changeDate(event,shipment){ 
    // Return date object
    alert("Change Date" +event.value);
    this.commonErrMsg = "";
    this.validateShipStatus = "";
      this.showReasonCodeErr = "";
      this.saveShipStatus = "";
      this.showCheckboxErr = "";
      this.confirmShipStatus = "";
      this.commonErrMsg = "";
      this.showSchDtErr = "";
      this.serErrMsg = "";
    shipment.isCheck = true;
   
    try{
    let dd = event.value.getDate();
    let mm = event.value.getMonth() + 1; //January is 0!
    let yyyy = event.value.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
  }
  catch(Exception){
    this.commonErrMsg = "Please select valid date";
  }
  if( event.value != undefined){
    //this.selectedSchDate =  yyyy+ '/' + mm+ '/' + dd ;
    //this.selectedOrderDate =  mm + '/' + dd + '/' + yyyy ;
    this.selectedOrderDate =this.datepipe.transform(event.value, 'MM/dd/yyyy'); 
    if(new Date(this.selectedOrderDate) < this.minDate)
    {
     // alert("Please select only future date");
      this.commonErrMsg ="Please select date greater than or equal to today's date";
      this.checkDateErr = false;
    }
    this.checkDateErr = true;
    //this.orderDate = this.selectedOrderDate ;
    //this.selectedOrderDate.toLocaleDateString();
    console.log("this.selectedSchDate:"+this.selectedOrderDate);
  }
  else
  {
    this.commonErrMsg ="Please select valid date";
  }
    console.log(event);
    //console.log('dd',dd);
    return this.commonErrMsg;
  }
  //this.configTab = 'orderList-tab-active';
  setTab(event:any){
    console.log(event);
    if(event == 'config'){
     this.configTab = 'orderList-tab-active dealerTabs';
     this.shipmentTab = '';
     this.compensationTab='';
     this.installCallTab='';
     this.contactsTab='';
     this.notesTab='';
     this.attachmentTab='';
     this.commonErrMsg = "";
 
     this.tableLoader = true; 
     this.orderDtlDealerService.getDealerDtlList(this.id,'CONFIG',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
      this.dealerOrderConfigList = data.data;
      this.tableLoader = false; 
      console.log('dealer order list',this.dealerOrderConfigList);
    //console.log(JSON.stringify(this.csaOrderList));
      this.totalconfigList=this.dealerOrderConfigList.length;
    });
   
    }else if (event == 'shipment') {
      this.pageLoad();
     this.shipmentTab = 'orderList-tab-active dealerTabs';
     this.validateShipStatus = "";
     this.showReasonCodeErr = "";
     this.saveShipStatus = "";
     this.showCheckboxErr = "";
     this.showSchDtErr = "";
     this.commonErrMsg = "";
     this.confirmShipStatus ="";
     this.serErrMsg = "";
     this.tableLoader = true; 
     this.masterShipmentSelected = false;
     this.orderDtlDealerService.getDealerDtlList(this.id,'SHIPMENT',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
      this.dealerOrderShipmentList = data.data;
    
      if(this.dealerOrderShipmentList != null || this.dealerOrderShipmentList != undefined)
      {
      for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
        if(this.dealerOrderShipmentList[i].schDateString != "")
        this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);       
      }
      this.massApplySelected =false;
      this.masterShipmentSelected =false;
      console.log('shipment order dealer list',this.dealerOrderShipmentList);
      //console.log(JSON.stringify(this.csaOrderList));
      this.totalshipmentList=this.dealerOrderShipmentList.length;
      this.tableLoader = false; 
      }
    },
  (error) =>
  {
    this.tableLoader = false; 
     this.commonErrMsg = "";
     console.log("Error:getDealerDtlList "+error);
     this.commonErrMsg =error;
  });
     this.configTab = '';
     this.compensationTab='';
     this.installCallTab='';
     this.contactsTab='';
     this.notesTab='';
     this.attachmentTab='';
    }
    else if (event == 'compensation') {
      
      this.compensationTab = 'orderList-tab-active dealerTabs';
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.orderDtlDealerService.getDealerDtlList(this.id,'COMPENSATION',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
        this.dealerOrderCompensationList = data.data;
        console.log('compensation order dealer list',this.dealerOrderCompensationList);
        //console.log(JSON.stringify(this.csaOrderList));
        for (var i = 0; i < this.dealerOrderCompensationList.length; i++) {       
         this.delearCompFlag=this.dealerOrderCompensationList[i].gsd;       
        }
        console.log("delearCompFlag::"+ this.delearCompFlag);
      this.totalcompensationList=this.dealerOrderCompensationList.length;
      this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error:getDealerDtlList "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.installCallTab='';
      this.contactsTab='';
      this.notesTab='';
      this.attachmentTab='';
     }
     else if (event == 'installCall') {
     
      this.installCallTab = 'orderList-tab-active dealerTabs';
      this.tableLoader = true; 
      this.orderDtlDealerService.getDealerDtlList(this.id,'INSTALL_CALL',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
        this.dealerOrderInstallCallList = data.data;
        console.log('Insatll order dealer list',this.dealerOrderInstallCallList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalinstallCallList=this.dealerOrderInstallCallList.length;
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error:getDealerDtlList "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.contactsTab='';
      this.notesTab='';
      this.attachmentTab='';
     }
     else if (event == 'contacts') {
     
      this.contactsTab = 'orderList-tab-active dealerTabs';
      this.showContactErr = ""; 
      this.saveStatus = "";
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.masterContactsSelected = false;
      this.orderDtlDealerService.getDealerDtlList(this.id,'CONTACTS',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
        this.dealerOrderContactList = data.data;
        console.log('contacts order dealer list',this.dealerOrderContactList);
        //console.log(JSON.stringify(this.csaOrderList));
        for (var i = 0; i < this.dealerOrderContactList.length; i++) {     
          if(this.dealerOrderContactList[i].custSecContactName != '' && this.dealerOrderContactList[i].custSecContactPhone != '' && this.dealerOrderContactList[i].custSecContactEmail != '')  
              this.disableSaveFlag=true; 
              else
              this.disableSaveFlag=false;  
         }
      this.totalcontactList=this.dealerOrderContactList.length;
      this.tableLoader = false;
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error:getDealerDtlList "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.installCallTab='';
      this.notesTab='';
      this.attachmentTab='';
     }
     else if (event == 'notes') {
      this.notesTab = 'orderList-tab-active dealerTabs';
      this.showContactErr = ""; 
      this.saveStatus = "";
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.masterContactsSelected = false;
      this.orderDtlDealerService.getDealerDtlList(this.id,'NOTES',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
        this.dealerOrderNotesList = data.data;
        console.log('notes order csa list',this.dealerOrderNotesList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalnotesList=this.dealerOrderNotesList.length;     
       this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.installCallTab='';
      this.contactsTab='';
      this.attachmentTab='';
     }
     else if (event == 'attachments') {
      this.attachmentTab= 'orderList-tab-active dealerTabs';
      this.showContactErr = ""; 
      this.saveStatus = "";
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.masterContactsSelected = false;
      this.orderDtlDealerService.getDealerDtlList(this.id,'ATTACHMENTS',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
        this.dealerOrderAttachmentsList = data.data;
        console.log('Attachments order csa list',this.dealerOrderAttachmentsList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalAttachmentList=this.dealerOrderAttachmentsList.length;     
       this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.installCallTab='';
      this.contactsTab='';
      this.notesTab='';
     }

  }
 setDealersCSS() {
    $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
    $(".site-logo").attr("src", "assets/img/ep-dealers-logo.png");
    $(".csa-page-header-title").hide();
    //$(".csa-page-header-title").show();
    $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
    $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
    $(".main-top-navbar").css("border-top", "1px solid #fff");
    $(".fa-ellipsis-v").css("color", "#396f96");
    $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #1b6d85", "background-color": "#7395ae", "color": "#fff" });

  
        $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");

        $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");

    $(".side-more-icons-wrapper").hover(() =>{
        $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
    }, () =>{
        $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
    });
    $(".side-menu-button-wrapper i").hover(() => {
        $(this).css({ "color": "rgb(38, 154, 188)", "border": "3px solid rgb(38, 154, 188)" });
    }, () =>{
        $(this).css({ "color": "#fff", "border": "3px solid #fff" });
    });
    $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
    $(".group-switcher").css({
            "border-color": "#999",
            "background-color": "#999 !important",
            "pointer-events": "none"
        });
          $("#main-top-nav-switcher").css({ 
            "border-color": "#999",
            "background-color": "#999",

        });
      $("#main-menu-switcher").css({ 
            "border-color": "#999",
            "background-color": "#999",

        });
    $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
    //$(".main-csa-content-wrapper,.csa-page-header-title").show();
    $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
    $(".btn-primary").css({ "border-color": "#2aabd1", "background-color": "#2286a3", "color": "#fff" });
    $(".result-table tr td i").css({ "color": "#2aabd1", "border": "solid 1px #2aabd1" });
    $("html,body").css({ "background-color": "#ddd" });
    $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({ "color": "#fff" });
    $(".result-table tr th").css({ "background-color": "#7395ae" });

    $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
    $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");

}

  openModel(lineId)
  {
   // alert("openModel");
   this.commonGsdErrMsg = "";
     this.orderDtlDealerService.getGsdComp(this.ord ,this.id,lineId).subscribe(data => {
      this.dealerOrderGsd = data.data;
      console.log('GSD order list',this.dealerOrderGsd);
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
       this.commonGsdErrMsg = "";
       console.log("Error: "+error);
       this.commonGsdErrMsg =error;
    });
  }
  openDeliveryPickupHistory(lineId)
  {
   // alert("openModel");
   this.validateShipStatus = "";
   this.showReasonCodeErr = "";
   this.saveShipStatus = "";
   this.showCheckboxErr = "";
   this.confirmShipStatus = "";
   this.commonDelErrMsg = "";
   this.showSchDtErr = "";
   this.serErrMsg = "";
   if(lineId == undefined)
       lineId="";
     this.orderDtlDealerService.getDelPickupHist(this.ord ,this.id,lineId,this.headerId).subscribe(data => {
      this.csaOrderDelHist = data.data;
      console.log('DelHistory order list',this.csaOrderDelHist.length);
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
       this.commonDelErrMsg = "";
       console.log("Error: "+error);
       this.commonDelErrMsg =error;
    });
    
  }
  tempArr: any = { "contacts": [] };
  
  contactsChange(param,event){ // Use appropriate model type instead of any
    if ( event.target.checked )
    { 
      if(param.lineId !="" || param.lineId != null)
      this.tempArr.contacts.push(param);
      $('#saveContactBtn').attr("disabled", false);        
    }
    else
    {    
      param.splice(param.lineId,1);
      $('#saveContactBtn').attr("disabled", true);
    }
    
  }

  getFormattedDate(tDate){
    let dd = tDate.getDate();
    let mm = tDate.getMonth() + 1; //January is 0!
    let yyyy = tDate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return  mm + '-' + dd + '-' + yyyy ;  
}
contactsAutoCheck(contact)
    {
      this.showContactErr = "";
      this.commonErrMsg = "";
      this.saveInfoMsg = "";
      contact.isCheck = true;
    }
    saveContacts()
    {
      //alert("Clicked Save");
      this.contactCheckedList= this.getCheckedContactList();
      this.showContactErr = "";
      this.commonErrMsg = "";
      this.saveInfoMsg = "";
      if(this.contactCheckedList.length != 0)
      {
      console.log("contactCheckedList:"+this.contactCheckedList);
      this.orderDtlDealerService.saveDealerContacts(this.contactCheckedList,this.ord ,this.id,this.headerId,this.shipOrgId).subscribe(data => {          
        this.showContactErr = "";              
        console.log('saveStatus order list',this.saveStatus);
        console.log('SuccessMsg from save',data[0]); 
        if(data[0] == 'No Changes to Save')
        {
            this.saveInfoMsg =data[0];
        }
        else
        this.saveStatus = data[0];   
       // $("#save-contact-detils-modal").modal("show");
        this.orderDtlDealerService.getDealerDtlList(this.id,'CONTACTS',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
          this.dealerOrderContactList = data.data;
          this.masterContactsSelected = false;
          console.log('Dealer order contact list',this.dealerOrderContactList);
          for (var i = 0; i < this.dealerOrderContactList.length; i++) {     
            if(this.dealerOrderContactList[i].custSecContactName != '' && this.dealerOrderContactList[i].custSecContactPhone != '' && this.dealerOrderContactList[i].custSecContactEmail != '')  
                this.disableSaveFlag=true; 
                else
                this.disableSaveFlag=false;  
           }
        this.totalcontactList=this.dealerOrderContactList.length;
        },
        (error) =>
        {
           this.commonErrMsg = "";
           console.log("Error: "+error);
           this.commonErrMsg =error;
        });     
      },
      (error) =>
      {
         this.commonErrMsg = "";
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
    }
    else
        {
          this.saveStatus = "";
          this.commonErrMsg = "";
          this.saveInfoMsg = "";
          this.showContactErr = "Please check the checkbox and click save";
        }
    }
 //Click event on parent checkbox  
 parentCheck(parentObj) {
  this.validateShipStatus = "";
  this.showReasonCodeErr = "";
  this.saveShipStatus = "";
  this.showCheckboxErr = "";
  this.confirmShipStatus = "";
  this.commonDelErrMsg = "";
  this.showSchDtErr = "";
  this.serErrMsg = "";
    if((parentObj.itemType == 'EQUIPMENT' && parentObj.isCheck == true) || ((parentObj.itemType == 'SW_CONFIG' && parentObj.isCheck == true)))
    {
      this.parentId=parentObj.lineNum;
      this.parentRma=parentObj.rma;
      //this.parentSchDate =parentObj.schDateString;
      if(parentObj.scheduldedDelDate != null)
      this.parentSchDate = this.datepipe.transform(parentObj.scheduldedDelDate, 'MM/dd/yyyy');
      else
      this.parentSchDate = "";
    for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {

      if(this.dealerOrderShipmentList[i].equipmentLineNum == this.parentId  && this.dealerOrderShipmentList[i].rma == this.parentRma &&  this.dealerOrderShipmentList[i].disableProp != "true")
      {
        this.dealerOrderShipmentList[i].isCheck =true;        
        //this.dealerOrderShipmentList[i].scheduldedDelDate == " "?this.parentSchDate: this.dealerOrderShipmentList[i].scheduldedDelDate;
        // this.dealerOrderShipmentList[i].schDateString = this.parentSchDate;
        // if(this.dealerOrderShipmentList[i].schDateString != "" && this.dealerOrderShipmentList[i].scheduldedDelDate == null)
        // this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);

      }
    }
  } 
 
}


parentCheckContacts(parentObj) {
  if(parentObj.itemType == 'EQUIPMENT' && parentObj.isCheck == true)
  {
    this.parentId=parentObj.lineNum;

  for (var i = 0; i < this.dealerOrderContactList.length; i++) {
    if(this.dealerOrderContactList[i].equipmentLineNum == this.parentId )
    {
      this.dealerOrderContactList[i].isCheck =true;
    }
  }
}
  
}
setSortOrder(sortBy,sortOrder) {
  this.selectedSortBy = sortBy;
  this.selectedSortOrder = sortOrder;
  this.orderDtlDealerService.getDealerDtlList(this.id,'SHIPMENT',this.ord,this.selectedSortBy,this.selectedSortOrder,this.approvalId).subscribe(data => {
    this.dealerOrderShipmentList = data.data;
    console.log('shipment order dealer list',this.dealerOrderShipmentList);
    if(this.dealerOrderShipmentList != null || this.dealerOrderShipmentList != undefined)
    {
    for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {

       if(this.dealerOrderShipmentList[i].schDateString != "")
        this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);   
    }
    //console.log(JSON.stringify(this.csaOrderList));
  this.totalshipmentList=this.dealerOrderShipmentList.length;
    }
  },
  (error) =>
  {
     this.commonErrMsg = "";
     console.log("Error: "+error);
     this.commonErrMsg =error;
  }); 
}
expandCollapse(obj){
  obj.isClosed = !obj.isClosed;
}

    shipmentCheckUncheckAll() {
      this.validateShipStatus = "";
      this.showReasonCodeErr = "";
      this.saveShipStatus = "";
      this.showCheckboxErr = "";
      this.confirmShipStatus = "";
      this.commonDelErrMsg = "";
      this.showSchDtErr = "";
      this.serErrMsg = "";
      for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
        if(this.dealerOrderShipmentList[i].disableProp != "true")
        {
           this.dealerOrderShipmentList[i].isCheck = this.masterShipmentSelected;
        }
      }
      this.getCheckedShipmentList();
    }
    contactCheckUncheckAll() {
      for (var i = 0; i < this.dealerOrderContactList.length; i++) {
        if(this.dealerOrderContactList[i].disableProp != "true")
        {
        this.dealerOrderContactList[i].isCheck = this.masterContactsSelected;
        if(this.dealerOrderContactList[i].isCheck == true)
        {          
           this.parentCustName =this.dealerOrderContactList[0].custSecContactName;
           this.parentCustPhone=this.dealerOrderContactList[0].custSecContactPhone;
           this.parentCustEmail=this.dealerOrderContactList[0].custSecContactEmail;
           this.dealerOrderContactList[i].custSecContactName = this.dealerOrderContactList[i].custSecContactName == "" ?this.parentCustName: this.dealerOrderContactList[i].custSecContactName;
           this.dealerOrderContactList[i].custSecContactPhone = this.dealerOrderContactList[i].custSecContactPhone == "" ?this.parentCustPhone: this.dealerOrderContactList[i].custSecContactPhone;
           this.dealerOrderContactList[i].custSecContactEmail = this.dealerOrderContactList[i].custSecContactEmail == "" ?this.parentCustEmail: this.dealerOrderContactList[i].custSecContactEmail;

        }
        }
      }
      this.getCheckedContactList();
    }
    isAllShipmentSelected() {
     // $('#confirmShipBtn').attr("disabled", false);
     // $('#saveShipBtn').attr("disabled", false);
      this.masterShipmentSelected = this.dealerOrderShipmentList.every(function(shiment:any) {
          return shiment.isCheck == true;
        })
      this.getCheckedShipmentList();
      //return this.shipmentCheckedList;
    }
    getSaveParam (param) { 
       
      return {
        "lineId":param.lineId,
        "itemCode":param.itemCode,
        "itemDesc":param.itemDesc,
        "lineNum":param.lineNum,
        "rma":param.rma,
        "serialNum":param.serialNum == null ? "" :param.serialNum.trim(),       
        "scheduldedDelDate":this.formatDate (param.scheduldedDelDate ),        
        "reasonCode":param.reasonCode == null ? "" :param.reasonCode.trim(),
        "notes":param.schedulingNotes == null ? "" :param.schedulingNotes.trim(),
        "contactName": param.custSecContactName == null ? " ": param.custSecContactName.trim(),
        "phoneNumber":param.custSecContactPhone  == null ? " ": param.custSecContactPhone.trim(),
        "email": param.custSecContactEmail  == null ? " ": param.custSecContactEmail.trim(),     
        "modelBlkMtrRdStart":parseInt(param.modelBlkMtrRdStart) == 0 ? 0: parseInt(param.modelBlkMtrRdStart),
        "modelBlkMtrRdEnd":parseInt(param.modelBlkMtrRdEnd) == 0 ? 0: parseInt(param.modelBlkMtrRdEnd),
        "modelClrMtrRdStart":parseInt(param.modelClrMtrRdStart)  == 0 ? 0: parseInt(param.modelClrMtrRdStart),
        "modelClrMtrRdEnd":parseInt(param.modelClrMtrRdEnd) == 0 ? 0: parseInt(param.modelClrMtrRdEnd),
  }
   
      }
   
   formatDate(sd){
     let sDate:any;
     try {
       sDate = this.datepipe.transform(sd, 'MM/dd/yyyy');
     } catch (error) {
      sDate=null;
     }
     return sDate;  
   }

  
    contactsCheckUncheckAll() {
      for (var i = 0; i < this.dealerOrderContactList.length; i++) {
        this.dealerOrderContactList[i].isCheck = this.masterContactsSelected;
      }
      this.getCheckedContactList();
    }
    isAllContactstSelected() {
     // $('#confirmShipBtn').attr("disabled", false);
     // $('#saveShipBtn').attr("disabled", false);
      this.masterContactsSelected = this.dealerOrderContactList.every(function(contacts:any) {
          return contacts.isCheck == true;
        })
      this.getCheckedContactList();
    }
  
    getCheckedContactList(){
      this.contactCheckedList = [];
      for (var i = 0; i < this.dealerOrderContactList.length; i++) {
        if(this.dealerOrderContactList[i].isCheck)
        {
          this.shipOrgId=this.dealerOrderContactList[i].shipToOrgId;        
        this.contactCheckedList.push(this.getSaveParam(this.dealerOrderContactList[i]));
        }
      }
      //this.contactCheckedList = JSON.stringify(this.contactCheckedList);
      return this.contactCheckedList;
    }
    shipmentAutoCheck(shipment)
    {
      this.validateShipStatus = "";
      this.showReasonCodeErr = "";
      this.saveShipStatus = "";
      this.showCheckboxErr = "";
      this.confirmShipStatus = "";
      this.commonErrMsg = "";
      this.showSchDtErr = "";
      this.serErrMsg = "";
      shipment.isCheck = true;
      let  bReqSchDelPkpDate = this.checkReasonCodeDelPkpDateReq(shipment);

      for(let k=0;k<this.dealerOrderShipmentList.length;k++){
          
           let oShp= this.dealerOrderShipmentList[k];
           if(shipment.lineId==oShp.lineId){
            this.dealerOrderShipmentList[k]["reqSchDelPkpDate"]=bReqSchDelPkpDate;
           }

      }

    }


    checkReasonCodeDelPkpDateReq(objShipment){
      let bReqSchDelPkpDate:boolean =false;
      let rsnCd=objShipment.reasonCode;
      for(let m=0;m<this.rsnCdList.length;m++){
          var objRsnCd=this.rsnCdList[m];
          if(objRsnCd.reasonCode==rsnCd){
             if(objRsnCd.deliveryPickupDateReq=="Y"){
              bReqSchDelPkpDate=true; 
              break;
             }
          }
      }
      
      return  bReqSchDelPkpDate;
    }


     getCheckedShipmentList(){
      this.shipmentCheckedList = [];
      this.reasonCodeList = [];
      for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
        if(this.dealerOrderShipmentList[i].isCheck)
        {

            this.shipmentCheckedList.push(this.getSaveParam(this.dealerOrderShipmentList[i]));                  
        }

      }
     // console.log("ShipmentCheckedList:"+this.shipmentCheckedList);
      return this.shipmentCheckedList;
     // this.shipmentCheckedList = JSON.stringify(this.shipmentCheckedList);   
    }

    checkReasonCode()
      {
        this.reasonCodeList = [];
        for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
        if(this.dealerOrderShipmentList[i].isCheck)
        {
          if(this.dealerOrderShipmentList[i].reasonCode == ""){
              this.reasonCodeErrMsg=this.dealerOrderShipmentList[i].itemCode;
              console.log("reasonCodeErrMsg:"+this.reasonCodeErrMsg);
              this.reasonCodeList.push(this.reasonCodeErrMsg);             
            }
         
         }
         
      }
      console.log("reasonCodeList:"+this.reasonCodeList);
      return this.reasonCodeList;
    }

    checkScheduleDate()
    {
      this.schdDtList = [];
      /*for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
      if(this.dealerOrderShipmentList[i].isCheck)
      {
        if(this.dealerOrderShipmentList[i].scheduldedDelDate == null){
            this.schDtErrMsg=this.dealerOrderShipmentList[i].lineNum;
            console.log("schDtErrMsg:"+this.schDtErrMsg);
            this.schdDtList.push(this.schDtErrMsg);             
          }
       
       }
       
    }*/

    if(this.validateScheduleDate()){
      this.schdDtList=this.arrSchDtLnId;
    }
    console.log("schdDtList:"+this.schdDtList);
    return this.schdDtList;
  }

  getReasonCodesList(){
    console.log("Getting ReasonCodesList");
    let oParam = {  };
    this.orderDtlDealerService.getReasonCodeList(oParam).subscribe(data => {
      this.rsnCdList  = data.data;
      //console.log(this.rsnCdList.toString);
    });
    
  }

  validateScheduleDate(){

    this.arrSchDtLnId=[];
    let bSchDtErr=false;
    for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
      let objShp =this.dealerOrderShipmentList[i]; 
   
      if(objShp.isCheck)
      {
              let bReq= this.checkReasonCodeDelPkpDateReq(objShp);
              if(bReq){
                 if(!objShp.scheduldedDelDate){
                  bSchDtErr=true;
                  this.arrSchDtLnId.push(objShp.lineNum);
                 }
              }               
      }

    }

    return bSchDtErr;


  }


  checkSerialNumber()
  {
    this.serialNumList = [];
    for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
    if(this.dealerOrderShipmentList[i].isCheck)
    {
      if(this.dealerOrderShipmentList[i].isSerialized == 'Y' && this.dealerOrderShipmentList[i].serialNum == ''){
          this.serErrMsg=this.dealerOrderShipmentList[i].lineNum;
          console.log("schDtErrMsg:"+this.serErrMsg);
          this.serialNumList.push(this.serErrMsg);             
        }
     
     }
     
  }
  console.log("serialNumList:"+this.serialNumList);
  return this.serialNumList;
}
    checkSaveReasonCode(shipmentCheckedList)
    {
      //console.log("Response shipmentCheckedList:"+shipmentCheckedList);
      this.reasonCodeList = [];
      for (var i = 0; i < this.shipmentCheckedList.length; i++) 
        {
         if(this.shipmentCheckedList[i].ssdChangeFlag == 'Y' || this.shipmentCheckedList[i].serialChangeFlag == 'Y' )
        	 {
		        if(this.shipmentCheckedList[i].reasonCode == "" || this.shipmentCheckedList[i].reasonCode == null){
		            this.reasonCodeErrMsg=this.shipmentCheckedList[i].lineNum;
		            console.log("reasonCodeErrMsg:"+this.reasonCodeErrMsg);
		            this.reasonCodeList.push(this.reasonCodeErrMsg);             
		        }
        	 }
       
    }
    console.log("checkSaveReasonCode:"+this.reasonCodeList);
    return this.reasonCodeList;
  }
    
      saveShipments()
      {
        this.shipmentCheckedList= this.getCheckedShipmentList();
                
        if(this.shipmentCheckedList.length != 0)
        {

          

          if(this.validateScheduleDate()){
            this.validateShipStatus = "";
            this.showReasonCodeErr = "";
            this.saveShipStatus = "";
            this.showCheckboxErr = "";
            this.confirmShipStatus = "";
            this.showSchDtErr= "";
            this.commonErrMsg = "Please select a Scheduled Delivery /Pickup Date for changed values of Line Numbers: "+this.arrSchDtLnId.toString();
            this.serErrMsg = "";
            this.validateShipStatus="";
            
            return false;
          }


         

          //this.shipmentCheckedList= this.getCheckedShipmentList();
          console.log("result:"+this.shipmentCheckedList);
          this.reasonCodeList= this.checkReasonCode();   
          console.log(":reasonCodeList"+this.reasonCodeList);

                console.log('this.shipmentCheckedList:',this.shipmentCheckedList);
                this.validateShipStatus = "";
                this.showReasonCodeErr = "";
                this.saveShipStatus = "";
                this.showCheckboxErr = "";
                this.confirmShipStatus = "";
                this.commonErrMsg = "";
                this.serErrMsg = "";
                //this.tableLoader = true; 
                this.orderDtlDealerService.shipmentDealerCheck(this.shipmentCheckedList,this.ord ,this.id,this.headerId,this.dealerNm).subscribe(data => {          
                      if(data[0] == 'No Changes')
                      {
                        //$("#showShipmentMsg").empty();
                        this.validateShipStatus = "";
                        this.showReasonCodeErr = "";
                        this.saveShipStatus = "";
                        this.showCheckboxErr = "";
                        this.confirmShipStatus = "";
                        this.showSchDtErr= "";
                        this.commonErrMsg = "";
                        this.serErrMsg = "";
                        this.validateShipStatus="No Change to save to the Shipment Details";
                        console.log('validateShipments',this.validateShipStatus);
                       
                        this.orderDtlDealerService.getDealerDtlList(this.id,'SHIPMENT',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
                          this.dealerOrderShipmentList = data.data;
                          this.masterShipmentSelected = false;
                          if(this.dealerOrderShipmentList != null || this.dealerOrderShipmentList != undefined)
                          {
                          console.log('shipment order dealer list',this.dealerOrderShipmentList);
                          for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
                             if(this.dealerOrderShipmentList[i].schDateString != "")
                             this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);  
                          }
                          //console.log(JSON.stringify(this.csaOrderList));
                        this.totalshipmentList=this.dealerOrderShipmentList.length;
                        //this.tableLoader = false; 
                          }
                        },
                        (error) =>
                        {
                          this.validateShipStatus = "";
                          this.showReasonCodeErr = "";
                          this.saveShipStatus = "";
                          this.showCheckboxErr = "";
                          this.confirmShipStatus = "";
                          this.showSchDtErr= "";
                          this.commonErrMsg = "";
                           this.serErrMsg = "";
                           this.tableLoader = false; 
                           console.log("Error: "+error);
                           this.commonErrMsg =error;
                        });   
                      }
                    else
                    {
                     this.shipmentCheckedList = data[1];
                     this.reasonCodeList= this.checkSaveReasonCode(this.shipmentCheckedList); 
                     this.tableLoader = false; 
                     if(this.reasonCodeList.length != 0)
                        {
                          this.validateShipStatus = "";
                            this.showReasonCodeErr = "";
                             this.saveShipStatus = "";
                            this.showCheckboxErr = "";
                            this.confirmShipStatus = "";
                            this.showSchDtErr= "";
                            this.commonErrMsg = "";
                            this.serErrMsg = "";
                            this.showReasonCodeErr="Please select a Reason Code for changed values of Line Numbers: "+this.reasonCodeList.toString();  
                        }
                     else{
                        this.validateShipStatus = "";
                        this.showReasonCodeErr = "";
                        this.saveShipStatus = "";
                        this.showCheckboxErr = "";
                        this.confirmShipStatus = "";
                        this.showSchDtErr= "";
                        this.commonErrMsg = "";
                        this.serErrMsg = "";
                        //this.tableLoader = true; 
                        this.orderDtlDealerService.saveshipmentDealer(this.shipmentCheckedList,this.ord ,this.id,this.headerId,this.dealerNm).subscribe(data => {          
                          if(data[0])
                          {                           
                             console.log('SuccessMsg from save',data[0]); 
                            this.saveShipStatus= "Selection SAVED Successfully!"; 
                            //this.tableLoader = false;  
                            $("#save-inventory-modal").modal("show");
                            this.orderDtlDealerService.getDealerDtlList(this.id,'SHIPMENT',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
                              this.dealerOrderShipmentList = data.data;
                              this.masterShipmentSelected = false;
                              console.log('shipment order dealer list',this.dealerOrderShipmentList);
                              if(this.dealerOrderShipmentList != null || this.dealerOrderShipmentList != undefined)
                              {
                              for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
                                if(this.dealerOrderShipmentList[i].schDateString != "")
                                this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);    
                              }
                              //console.log(JSON.stringify(this.csaOrderList));
                            this.totalshipmentList=this.dealerOrderShipmentList.length;
                          
                              }
                            },
                            (error) =>
                            {
                              this.validateShipStatus = "";
                              this.showReasonCodeErr = "";
                              this.saveShipStatus = "";
                              this.showCheckboxErr = "";
                              this.confirmShipStatus = "";
                              this.showSchDtErr= "";
                              this.commonErrMsg = "";
                             // this.tableLoader = false; 
                               console.log("Error: "+error);
                               this.commonErrMsg =error;
                            });
                          }
                          
                        },
                          (error) =>
                          {
                            this.validateShipStatus = "";
                            this.showReasonCodeErr = "";
                            this.saveShipStatus = "";
                            this.showCheckboxErr = "";
                            this.confirmShipStatus = "";
                            this.showSchDtErr= "";
                            this.commonErrMsg = "";
                            this.serErrMsg = "";
                             console.log("Error: "+error);
                             this.commonErrMsg =error;
                          }); 
                      
                      } 
                    }
                      

              });         
            //}

         
        }
         else{
         this.validateShipStatus = "";
         this.showReasonCodeErr = "";
         this.saveShipStatus = "";
         this.confirmShipStatus = "";
         this.showSchDtErr= "";
         this.commonErrMsg = "";
         this.serErrMsg = "";
         this.showCheckboxErr = "Please check the checkbox and click Save";         
           }
      }
      
     confirmShipments()
     {
      this.validateShipStatus = "";
      this.showReasonCodeErr = "";
      this.saveShipStatus = "";
      this.showCheckboxErr = "";
      this.showSchDtErr= "";
      this.commonErrMsg = "";
      this.serErrMsg = "";
      //this.tableLoader = true; 

     


      this.orderDtlDealerService.confirmDealerShipments(this.shipmentCheckedList,this.ord,this.id,this.headerId,this.dealerNm,this.custNm).subscribe(data => {
            if(data[0] != 'Error')
            {             
          this.confirmShipStatus=data[0];
          console.log('confirmShipStatus',this.confirmShipStatus);
          this.confirmedLines = data[1];
          console.log("confirmedLines:"+ this.confirmedLines );   
          this.orderDtlDealerService.getDealerDtlList(this.id,'SHIPMENT',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
            this.dealerOrderShipmentList = data.data;
            this.masterShipmentSelected = false;
            console.log('shipment order dealer list',this.dealerOrderShipmentList);
            if(this.dealerOrderShipmentList != null || this.dealerOrderShipmentList != undefined)
            {
            for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
              if(this.dealerOrderShipmentList[i].schDateString != "")
              this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);    
            }
            //console.log(JSON.stringify(this.csaOrderList));
          this.totalshipmentList=this.dealerOrderShipmentList.length;
         // this.tableLoader = false; 
            }
          },
          (error) =>
          {
            this.validateShipStatus = "";
            this.showReasonCodeErr = "";
            this.saveShipStatus = "";
            this.showCheckboxErr = "";
            this.confirmShipStatus = "";
            this.showSchDtErr= "";
            this.commonErrMsg = "";
            this.serErrMsg = "";
            //this.tableLoader = false; 
             console.log("Error: "+error);
             this.commonErrMsg =error;
          }); 
        }
        else{
          this.shipmentConfirmErrorMsg = data[1];
        }            
      });

     }
     machineCascadeValue()
     {  
      this.copyShipmentValFlag = true;
      for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
        if((this.dealerOrderShipmentList[i].itemType == 'EQUIPMENT' && this.dealerOrderShipmentList[i].isCheck == true && this.dealerOrderShipmentList[i].rma == 'N') || (this.dealerOrderShipmentList[i].itemType == 'SW_CONFIG' && this.dealerOrderShipmentList[i].isCheck == true && this.dealerOrderShipmentList[i].rma == 'N'))
       {
        this.parentId=this.dealerOrderShipmentList[i].lineNum;
        //this.parentReasonCode =this.dealerOrderShipmentList[i].reasonCode;
        this.parentNotes =this.dealerOrderShipmentList[i].schedulingNotes;
        if(this.dealerOrderShipmentList[i].scheduldedDelDate != null)
        this.parentSchDate = this.datepipe.transform(this.dealerOrderShipmentList[i].scheduldedDelDate, 'MM/dd/yyyy');
        else
        this.parentSchDate = "";
      for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
  
        if(this.dealerOrderShipmentList[i].equipmentLineNum == this.parentId  && this.dealerOrderShipmentList[i].rma == 'N' &&  this.dealerOrderShipmentList[i].disableProp != "true")
        {
          this.dealerOrderShipmentList[i].isCheck =true;        
          //this.dealerOrderShipmentList[i].reasonCode = this.dealerOrderShipmentList[i].reasonCode ==""?this.parentReasonCode: this.dealerOrderShipmentList[i].reasonCode;
          this.dealerOrderShipmentList[i].schedulingNotes = this.dealerOrderShipmentList[i].schedulingNotes == "" ?this.parentNotes: this.dealerOrderShipmentList[i].schedulingNotes;
          this.dealerOrderShipmentList[i].schDateString = this.parentSchDate;
          if(this.dealerOrderShipmentList[i].schDateString != "" && this.dealerOrderShipmentList[i].scheduldedDelDate == null)
          this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);
            this.copyShipmentValFlag =true;
        }
      }
       }  
     }
     return this.copyShipmentValFlag;
    }
    massApply()
    {
      this.copyShipmentValFlag = false;
      this.serErrMsg = "";   
      this.validateShipStatus = "";
      this.showReasonCodeErr = "";
      this.saveShipStatus = "";
      this.showCheckboxErr = "";
      this.confirmShipStatus = "";
      this.showSchDtErr= "";
      this.commonErrMsg = "";
      this.serErrMsg = "";
      this.shipmentCheckedList= this.getCheckedShipmentList();
      
      if(this.shipmentCheckedList.length != 0)
      {          
        if(this.masterShipmentSelected == true)
        {
        for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
          if(this.dealerOrderShipmentList[i].disableProp != "true" && this.dealerOrderShipmentList[i].isCheck == true)
          {
                        this.parentNotes =this.dealerOrderShipmentList[0].schedulingNotes;
                        this.parentReasonCode =this.dealerOrderShipmentList[0].reasonCode;
                        if(this.dealerOrderShipmentList[0].scheduldedDelDate != null)
                        this.parentSchDate = this.datepipe.transform(this.dealerOrderShipmentList[0].scheduldedDelDate, 'MM/dd/yyyy');
                        else
                        this.parentSchDate = "";
                        this.dealerOrderShipmentList[i].schedulingNotes = this.parentNotes;
                        this.dealerOrderShipmentList[i].schDateString = this.parentSchDate;
                        this.dealerOrderShipmentList[i].reasonCode =this.parentReasonCode;
                      // if(this.dealerOrderShipmentList[i].schDateString != "" && this.dealerOrderShipmentList[i].scheduldedDelDate == null)
                        this.dealerOrderShipmentList[i].scheduldedDelDate = new Date(this.dealerOrderShipmentList[i].schDateString);
                    }
                  }
      }
      else{
      for (var i = 0; i < this.dealerOrderShipmentList.length; i++) {
       // this.dealerOrderShipmentList[i].isCheck = this.massApplySelected;
        //if((this.dealerOrderShipmentList[i].itemType == 'EQUIPMENT' && this.dealerOrderShipmentList[i].isCheck == true) || (this.dealerOrderShipmentList[i].itemType == 'SW_CONFIG' && this.dealerOrderShipmentList[i].isCheck == true))
       if(this.dealerOrderShipmentList[i].isCheck == true)
        {
        
        this.parentId=this.dealerOrderShipmentList[i].lineNum;
        this.parentRma=this.dealerOrderShipmentList[i].rma
        this.parentReasonCode =this.dealerOrderShipmentList[i].reasonCode;
        this.parentNotes =this.dealerOrderShipmentList[i].schedulingNotes;
        if(this.dealerOrderShipmentList[i].scheduldedDelDate != null)
        this.parentSchDate = this.datepipe.transform(this.dealerOrderShipmentList[i].scheduldedDelDate, 'MM/dd/yyyy');
        else
        this.parentSchDate = "";
      for (var j = 0; j < this.dealerOrderShipmentList.length; j++) {
  
       // if(this.dealerOrderShipmentList[j].equipmentLineNum == this.parentId  && this.dealerOrderShipmentList[j].rma == this.parentRma &&  this.dealerOrderShipmentList[j].disableProp != "true" && this.dealerOrderShipmentList[j].isCheck == true)
       if(this.dealerOrderShipmentList[j].rma == this.parentRma &&  this.dealerOrderShipmentList[j].disableProp != "true" && this.dealerOrderShipmentList[j].isCheck == true)
       {
          //this.dealerOrderShipmentList[j].isCheck =true;        
          this.dealerOrderShipmentList[j].schedulingNotes = this.parentNotes;
          this.dealerOrderShipmentList[j].schDateString = this.parentSchDate;
          this.dealerOrderShipmentList[j].reasonCode =this.parentReasonCode;        
          this.dealerOrderShipmentList[j].scheduldedDelDate = new Date(this.dealerOrderShipmentList[j].schDateString);
            this.copyShipmentValFlag =true;
        }
        }       
       } 
        
     }
  //  if(this.copyShipmentValFlag == false )
  //  {
  //    this.commonErrMsg = "Accessory items Mass Apply is not applicable";
  //  }
  }
    }
else
{
  this.validateShipStatus = "";
  this.showReasonCodeErr = "";
  this.saveShipStatus = "";
  this.showCheckboxErr = "";
  this.confirmShipStatus = "";
  this.showSchDtErr= "";
  this.commonErrMsg = "";
  this.serErrMsg = "";
this.showCheckboxErr = "Please check the Machine checkbox or Select All to  Mass Apply ";    
}
     return this.copyShipmentValFlag;
    }

    validateconfirmShipments()
    {
      this.validateShipStatus = "";
      this.showReasonCodeErr = "";
      this.saveShipStatus = "";
      this.showCheckboxErr = "";
      this.confirmShipStatus = "";
      this.showSchDtErr= "";
      this.commonErrMsg = "";
      this.serErrMsg = "";
      this.shipmentCheckedList= this.getCheckedShipmentList();
      
        if(this.shipmentCheckedList.length != 0)
        {          
                 console.log("result:"+this.shipmentCheckedList);
                
                 this.serialNumList =this.checkSerialNumber();                
          if(this.serialNumList.length == 0)
           {
             this.schdDtList =this.checkScheduleDate();
             this.shipmentCheckedList= this.getCheckedShipmentList();
          if(this.schdDtList.length == 0)
            {
              this.validateShipStatus = "";
              this.showReasonCodeErr = "";
              this.saveShipStatus = "";
              this.showCheckboxErr = "";
              this.confirmShipStatus = "";
              this.commonErrMsg = "";
              this.serErrMsg = "";
             
              this.orderDtlDealerService.shipmentDealerCheck(this.shipmentCheckedList,this.ord ,this.id,this.headerId,this.dealerNm).subscribe(data => {        
                     if(data[0] == 'Changed')
                  {
                   this.shipmentCheckedList = data[1];
                   //console.log("Before reasonCode Check:"+JSON.stringify(this.shipmentCheckedList));
                   this.reasonCodeList= this.checkSaveReasonCode(this.shipmentCheckedList);                    
                   if(this.reasonCodeList.length != 0)
                      {
                        this.validateShipStatus = "";
                          this.showReasonCodeErr = "";
                           this.saveShipStatus = "";
                          this.showCheckboxErr = "";
                          this.confirmShipStatus = "";
                          this.showSchDtErr= "";
                          this.commonErrMsg = "";
                          this.serErrMsg = "";
                          this.showReasonCodeErr="Please select a Reason Code for changed values of Line Numbers: "+this.reasonCodeList.toString();  
                      }
                   else{
                    //this.shipmentCheckedList= this.getCheckedShipmentList();
                    //$('#showShipmentMsg').empty();
                      this.validateShipStatus = "";
                      this.showReasonCodeErr = "";
                      this.saveShipStatus = "";
                      this.showCheckboxErr = "";
                      this.confirmShipStatus = "";
                      this.showSchDtErr= "";
                      this.commonErrMsg = "";
                      this.serErrMsg = "";
                      console.log('this.shipmentCheckedList:',this.shipmentCheckedList);                           
                      $("#confirm-inventory-shipment").modal("show"); 
                    
                    } 
                  }
                else{
                    console.log('this.shipmentCheckedList:',this.shipmentCheckedList);
                    this.validateShipStatus = "";
                    this.showReasonCodeErr = "";
                    this.saveShipStatus = "";
                    this.confirmShipStatus = "";
                    this.showSchDtErr= "";
                    this.serErrMsg = "";                   
                    $("#confirm-inventory-shipment").modal("show");
                }

            },
            (error) =>
            {
              this.validateShipStatus = "";
              this.showReasonCodeErr = "";
              this.saveShipStatus = "";
              this.showCheckboxErr = "";
              this.confirmShipStatus = "";
              this.showSchDtErr= "";
              this.commonErrMsg = "";
              this.serErrMsg = "";
              this.tableLoader = false; 
               console.log("Error: "+error);
               this.commonErrMsg =error;
            }); 
             
                }
                else{
                  this.validateShipStatus = "";
                  this.showReasonCodeErr = "";
                  this.saveShipStatus = "";
                  this.showCheckboxErr = "";
                  this.confirmShipStatus = "";
                  this.showSchDtErr= "";
                  this.commonErrMsg = "";
                  this.serErrMsg = "";
                  this.showSchDtErr="Please select a Scheduled Delivery/Pickup Date  to Confirm the Shipment details for selected lineNumbers:"+this.schdDtList.toString();                  
                  }
                }
                  else{
                    this.validateShipStatus = "";
                    this.showReasonCodeErr = "";
                    this.saveShipStatus = "";
                    this.showCheckboxErr = "";
                    this.confirmShipStatus = "";
                    this.showSchDtErr= "";
                    this.commonErrMsg = "";
                    this.serErrMsg = "";
                    this.showSchDtErr="Please enter Serial Number  to Confirm the Shipment details for selected lineNumbers:"+this.serialNumList.toString();                  
                    }
                
              }
      
         else{      
         this.validateShipStatus = "";
         this.showReasonCodeErr = "";
         this.saveShipStatus = "";
         this.confirmShipStatus = "";
         this.showSchDtErr= "";
         this.commonErrMsg = "";
         this.serErrMsg = "";
         this.showCheckboxErr = "Please check the checkbox and click Confirm";         
           }

    }
    // validateconfirmShipments()
    // {
    //   this.shipmentCheckedList= this.getCheckedShipmentList();
      
    //   if(this.shipmentCheckedList.length != 0)
    //   {
    //     this.shipmentCheckedList= this.getCheckedShipmentList();
    //     console.log("result:"+this.shipmentCheckedList);
    //     this.confirmChkErrList=this.parentCheckConfirm();
    //     if(this.confirmChkErrList == 0)
    //     {
    //     this.reasonCodeList= this.checkReasonCode();   
    //     console.log(":reasonCodeList"+this.reasonCodeList);
    //     this.schdDtList =this.checkScheduleDate();
    //     if(this.schdDtList.length == 0)
    //       {
    //         // if(this.reasonCodeList.length != 0)
    //         // {
    //         //   this.validateShipStatus = "";
    //         //   this.showReasonCodeErr = "";
    //         //   this.saveShipStatus = "";
    //         //   this.showCheckboxErr = "";
    //         //   this.confirmShipStatus = "";
    //         //   this.showSchDtErr= "";
    //         //   this.commonErrMsg = "";
    //         //    this.showReasonCodeErr="Please select a Reason Code to Confirm the Shipment details for selected itemCodes:"+this.reasonCodeList.toString();        
    //         // }
    //         // else{
    //             console.log('this.shipmentCheckedList:',this.shipmentCheckedList);
    //             this.validateShipStatus = "";
    //             this.showReasonCodeErr = "";
    //             this.saveShipStatus = "";
    //             this.confirmShipStatus = "";
    //             this.showSchDtErr= "";
    //             $("#confirm-inventory-shipment").modal("show");
               
    //            // }
    //           }
    //           else{
    //             this.validateShipStatus = "";
    //             this.showReasonCodeErr = "";
    //             this.saveShipStatus = "";
    //             this.showCheckboxErr = "";
    //             this.confirmShipStatus = "";
    //             this.showSchDtErr= "";
    //             this.commonErrMsg = "";
    //             this.showSchDtErr="Please select a Scheduled Delivery/Pickup Date  to Confirm the Shipment details for selected itemCodes:"+this.schdDtList.toString();                  
    //             }
    //           }
    //             else{
    //               this.validateShipStatus = "";
    //               this.showReasonCodeErr = "";
    //               this.saveShipStatus = "";
    //               this.showCheckboxErr = "";
    //               this.confirmShipStatus = "";
    //               this.showSchDtErr= "";
    //               this.commonErrMsg = "";
    //               this.showCheckboxErr="Please select related machine/related accessories itemCode( "+ this.confirmChkErrList.toString()+" )to Confirm Shipment details";  
                 
    //               }

    //         }
    
    //    else{
    //    // alert("Please enter reason Code");

    //    //alert("Please Check the checkbox");
    //    this.validateShipStatus = "";
    //    this.showReasonCodeErr = "";
    //    this.saveShipStatus = "";
    //    this.confirmShipStatus = "";
    //    this.showSchDtErr= "";
    //    this.commonErrMsg = "";
    //    this.showCheckboxErr = "Please check the checkbox and click Confirm";         
    //      }

    // }
      

onNavigate(params,event) {
  event.preventDefault();
   console.log(params);
   this.commonErrMsg = "";
   var pgUrl = window.location.host;
   var pgUrl = "http://localhost:8080";
   this.defaultUrlNew = this.util.getBaseUrl();
   var encodedFileName= encodeURIComponent(params.fileName);
   var link = this.defaultUrlNew+'orderDtlDealer/da?requestId='+params.requestId+'&orderNumber='+params.orderNumber+'&attachmentId='+params.attachmentId+'&fileIdentifierId='+params.fileIdentifierId+'&dataType='+'FILE'+'&fileName='+encodedFileName+'&schema='+params.schema;
    console.log('upload url is : ',link);
   window.open(link, "_blank");
   }
   
   getOrderDtlHeader()
  {
   // alert("openModel");
   this.commonGsdErrMsg = "";
     this.orderDtlDealerService.getOrderDtlHeaderSec(this.ord ,this.id).subscribe(data => {
      
      this.ohaddress =data.ohaddress;
      this.ohcontactname =data.ohcontactname;
      this.ohcontactphone =data.ohcontactphone;
      this.ohcontactemail =data.ohcontactemail;
      this.ohtotal =data.ohtotal;
      this.ohcomptotal =data.ohcomptotal;
      this.ohexpensetotal =data.ohexpensetotal;
      this.ohitemstotal =data.ohitemstotal;
      this.ohitemstotal =data.ohitemstotal;
      this.ohitemstotal =data.ohitemstotal;
      this.custNm =data.ohcustname;
      this.reqdd =data.ohrdd;
      this.refPoNum =data.ohdealerpo;
     // console.log('GSD order list',this.dealerOrderGsd);
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
       this.commonGsdErrMsg = "";
       console.log("Error: "+error);
       this.commonGsdErrMsg =error;
    });
  }
  addNotes()
  {
    this.submitNotesBtn = false;
    this.saveNotesStatus ="";
    this.newcomment='';
    this.saveNotesErrorStatus ='';
    $("#addnotes-modal").modal("show");
    
  }
  submitNotes()
  {
    this.saveNotesStatus ="";
    this.submitNotesBtn = false;
    this.saveNotesErrorStatus ='';
    if(this.newcomment != ''){
    this.orderDtlDealerService.saveNotesDetails(this.ord,this.id,this.headerId,this.dealerNm,this.custNm,this.newcomment,this.loggedUserName).subscribe(data => {
      if(data[0] != 'Error')
      {             
    this.saveNotesStatus=data[5];
    console.log('saveNotesDetails',this.saveNotesStatus);
    this.submitNotesBtn = true;
      }
      else
      this.commonErrMsg=data[5];
      //console.log(JSON.stringify(this.csaOrderList));
     this.tableLoader = false; 
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.saveNotesStatus ='';
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
  }
  else
  {
    this.submitNotesBtn = false;
    this.commonErrMsg = "";
    this.saveNotesStatus ='';
    this.saveNotesErrorStatus ='';
    this.saveNotesErrorStatus ='Please add notes in text area and click submit';
  }
  }
  closeNotes()
  {
  
    this.showContactErr = ""; 
    this.saveStatus = "";
    this.commonErrMsg = "";
    this.tableLoader = true; 
    this.saveNotesStatus ="";
    this.orderDtlDealerService.getDealerDtlList(this.id,'NOTES',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
      this.dealerOrderNotesList = data.data;
      console.log('notes order csa list',this.dealerOrderNotesList);
      //console.log(JSON.stringify(this.csaOrderList));
    this.totalnotesList=this.dealerOrderNotesList.length;    
     this.tableLoader = false; 
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
  }
  closeAttachments()
  {
  
    this.orderDtlDealerService.getDealerDtlList(this.id,'ATTACHMENTS',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
      this.dealerOrderAttachmentsList = data.data;
      console.log('Attachments order dealer list',this.dealerOrderAttachmentsList);
      //console.log(JSON.stringify(this.csaOrderList));
    this.totalAttachmentList=this.dealerOrderAttachmentsList.length;     
     this.tableLoader = false; 
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
  }
   
  getOrderLineParam (orderNumber,ittNumber) {
    return {
      "orderNumber": orderNumber, 
      "ittNumber": ittNumber
    
     // "lineId": lineId
       }
    }
    getSearchOrderLineParam (orderNumber,ittNumber) {
      return {
        "orderNumber": orderNumber, 
        "ittNumber": ittNumber,
        "installReturn": this.searchInstall,
        "lineNumber": this.searchOrderLine,
        "item": this.searchOrderItem,
        "serviceModel": this.searchOrderServiceModel,
        "itemDesc": this.searchOrderDescription,
        "serialNumber": this.searchSerialNumber
         }
      }
    searchOrderLineBtn(orderNumber,ittNumber)
    {
      orderNumber=this.ord;
      ittNumber =this.id;  
      this.masterOrderLineSelected = false;
      this.orderDtlDealerService.getSearchLineAttachments(this.getSearchOrderLineParam(orderNumber,ittNumber)).subscribe(data => {
        this.selectedOrderLine = data.data;
       
      console.log("Uploaded Docs List:"+this.selectedOrderLine);
        $("#orderLineModal").modal("show");
        console.log('data passed to modal: ',this.selectedOrderLine);
        
      },
      (error) =>
      {
         this.commonErrMsg = "";  
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
    }
  openOrderLineData(orderNumber,ittNumber,requestId)
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.fileMsg = "";
    this.checkBoxErrorMsg= "";
    this.orderLineNumberValue = "";
    this.searchInstall = "";
    this.masterOrderLineSelected = false;
    this.orderLineCheckedList =[];
   // alert("openModel");
   if(this.selectedDocType == "POD/Pickup Confirmation")
    {
   orderNumber=this.ord;
   ittNumber =this.id;
    
   this.orderDtlDealerService.getSearchLineAttachments(this.getOrderLineParam(orderNumber,ittNumber)).subscribe(data => {
    this.selectedOrderLine = data.data;
   
  console.log("Uploaded Docs List:"+this.selectedOrderLine);
    $("#orderLineModal").modal("show");
    console.log('data passed to modal: ',this.selectedOrderLine);
    
  },
  (error) =>
  {
     this.commonErrMsg = "";  
     console.log("Error: "+error);
     this.commonErrMsg =error;
  });
  }
  else
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.warningFileMsg = "Please select Document Type";
  }
  }
  openFileUpload()
  {
    this.commonErrMsg = "";
    this.commonFileErrMsg = "";
    this.fileMsg = "";
   // this.docTypebtn = true;
    //this.selectedDocType ='';
  }
  uploadDocService(param){
    console.log('order id is:'+param);     
         this.commonErrMsg = "";
         this.commonErrMsg = "";
         this.commonFileErrMsg = "";
         this.fileMsg ="";
    this.orderDtlDealerService.getUploadedDocs(param).subscribe(data => {
    this.selectedUploadedOrder = data.data;
   
  console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
   $("#uploadDocModal").modal("show");
   this.docTypebtn = false;
   // console.log('data passed to modal: ',this.selectedUploadedOrder);
  },
  (error) =>
  {
     this.commonErrMsg = "";  
     console.log("Error: "+error);
     this.commonErrMsg =error;
  });
  }
  
  getFileAttParam (orderNumber) {
  return {
    "orderNumber": this.ord,
     }
  }
  uploadDoc(){
    this.fileMsg = "";
    this.commonErrMsg = "";
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.checkBoxErrorMsg= "";
    this.searchInstall = "";
    this.selectedDocType ="";
    this.desc ="";
    this.orderLineNumberValue = "";
    $("#customFile").val(''); 
    
    this.uploadDocService(this.getFileAttParam(this.orderNumber));
  
   }
  
   handleFileInput(files: FileList) {   
    this.fileToUpload = files.item(0);
     //this.uploadFileToActivity();
  }
  openDeleteFileModal(fileName,attachId)
  {
  this.commonFileErrMsg = "";
  this.fileMsg = "";
  this.checkBoxErrorMsg= "";
  this.searchInstall = "";
  this.selectedDocType ="";
  this.orderLineNumberValue = "";
  $("#delete-file-modal").modal("show");
  this.fileName=fileName;
  this.attachId=attachId;
  }
  clearOrderLineButton() {    
    this.searchInstall = "";
    this.searchOrderLine = "";
    this.searchOrderItem = "";
    this.searchOrderServiceModel = "";
    this.searchOrderDesc = "";
    this.searchOrderSerNum = "";
    this.masterOrderLineSelected = false;
    this.orderLineNumberValue = "";
    this.searchOrderLineBtn(this.ord,this.id);
  }
  deleteDoc(attachId)
  {
  this.commonFileErrMsg = "";
  this.warningFileMsg = "";
  this.warningFileDocMsg = "";
  this.fileMsg = "";
  this.fileMainMsg  ="";
  this.orderLineNumberValue = "";
  this.orderDtlDealerService.deleteUploadedDoc(attachId,"",this.id).subscribe(data => {
    this.commonFileErrMsg = "";
    if(data.status == 'Successfully Deleted the attachment')
    {
      $("#delete-file-modal").modal("hide");
      this.fileMsg = data.status;
      this.fileMainMsg = data.status;
      this.orderDtlDealerService.getUploadedDocs(this.getFileAttParam(this.ord)).subscribe(data => {        
        this.selectedUploadedOrder = data.data;
        this.fileMsg = "";
        this.fileMainMsg = "";
        this.docTypebtn = false;
         console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
      },
      (error) =>
      {
         this.commonFileErrMsg = "";
         this.warningFileMsg = "";
         this.warningFileDocMsg = "";
         this.fileMsg = "";
         this.fileMainMsg = "";
         console.log("Error: "+error);
         this.commonFileErrMsg =error;
      });

      this.orderDtlDealerService.getDealerDtlList(this.id,'ATTACHMENTS',this.ord,'LINE_NUMBER','ASC',this.approvalId).subscribe(data => {
        this.dealerOrderAttachmentsList = data.data;
        console.log('Attachments order dealer list',this.dealerOrderAttachmentsList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalAttachmentList=this.dealerOrderAttachmentsList.length;     
       this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
    }
    else
    {
      this.commonFileErrMsg = "";
      this.fileMsg = "";
      this.fileMainMsg = "";
      this.commonFileErrMsg = "Some Error Occured while deleteing the file";
     
    }
  },
  error =>
  {
    this.commonFileErrMsg = "";
    this.fileMsg = "";
    this.fileMainMsg = "";
    console.log("Error: "+error);
    this.commonFileErrMsg =error;
  });
  }
  validateFileType(fileToUpload)
  {
   if(fileToUpload.type == 'application/pdf' || fileToUpload.type == 'application/msword' ||fileToUpload.type == 'application/vnd.ms-excel'|| fileToUpload.type == 'text/plain' || fileToUpload.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileToUpload.type ==  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  this.valFiletype = true;
  else
  this.valFiletype =  false;
  
  return this.valFiletype;
  }
  validateFileSize(fileToUpload)
  {
    const fsize = fileToUpload.size;
    const file = Math.round((fsize / 1024)); 
     if (file >= 2048)                 
      this.valFileSize = false;
    else
      this.valFileSize =  true;
  
  return this.valFileSize;
  }
  clearFileDtls(s: string) {
  this.desc = '';
  this.fileName = null;
  
  if (s == "I") {
    this.warningFileMsg = '';
    this.fileMsg = '';
  }
  }
  orderLineCheckUncheckAll() { 
    this.orderLineNumberValue = "";
    for (var i = 0; i < this.selectedOrderLine.length; i++) {
   
         this.selectedOrderLine[i].isCheck = this.masterOrderLineSelected;
    }
    this.getCheckedOrderLineList();
  }
  getOrderLineSaveParam (param) { 
    return {
      "lineNumber":param.lineNumber,
      "serialNumber":param.serialNumber,
      "itemDesc":param.itemDesc,
      "item":param.item,
      "installReturn":param.installReturn,
  }
  
    }
    parentCheckOrderLine(parentObj) {
      this.orderLineNumberValue = "";
      if( parentObj.isCheck == true)
      {
        this.parentOrderLineId=parentObj.lineNumber;
    
      for (var i = 0; i < this.selectedOrderLine.length; i++) {
        if(this.selectedOrderLine[i].lineNumber == this.parentOrderLineId )
        {
          this.selectedOrderLine[i].isCheck =true;
        }
      }
    }
    this.getCheckedOrderLineList();
  }
    
    getCheckedOrderLineList() {
      this.orderLineCheckedList = [];
      this.orderLineNumberValue = "";
    this.conCatLineNumber= "";
        for (var i = 0; i < this.selectedOrderLine.length; i++) {
          if(this.selectedOrderLine[i].isCheck)
          {
  
              this.orderLineCheckedList.push(this.getOrderLineSaveParam(this.selectedOrderLine[i]));                  
          }
  
        }
        console.log("orderLineCheckedList:"+this.orderLineCheckedList);
      //  throw new Error('Method not implemented.');
      if(this.searchInstall == "ALL"  && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
      {
        this.orderLineNumberValue = "ALL";
      }
      else if(this.searchInstall == "INSTALL"  && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
      {
        this.orderLineNumberValue = "Installation Only - All";
      }
      else if(this.searchInstall == "RETURN"  && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
      {
        this.orderLineNumberValue = "Return Only - All";
      }
      else
      {
        for (var i = 0; i < this.orderLineCheckedList.length; i++) {
          if(this.orderLineCheckedList.length>1)
          {
            if(i== 0)
            this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
            else
            this.conCatLineNumber+=","+this.orderLineCheckedList[i].lineNumber;
          }
        else
        this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
        }
        this.orderLineNumberValue =this.conCatLineNumber;
      }
    
        return this.orderLineCheckedList;
    }
    finalOrderLineCheckedList()
    {
     if(this.orderLineCheckedList == undefined)
       {
         this.checkBoxErrorMsg = "Please select atleast one line";
       }
       else
       $("#orderLineModal").modal("hide");
    }
    opendocType()
    {
      this.orderLineNumberValue = "";
      this.searchInstall = "";
      if(this.selectedDocType == "Misc")
      this.docTypebtn = true;
      else if(this.selectedDocType == "POD/Pickup Confirmation")
      this.docTypebtn = false;
      else
      this.docTypebtn = true;
    }
  uploadFileToActivity() {
  this.fileMsg = "";
  this.fileMainMsg = "";
  this.commonErrMsg = "";
  this.warningFileMsg = "";
  this.warningFileDocMsg = "";
  this.commonFileErrMsg = "";
  if(this.desc == undefined)
    this.desc = "";
    
  if(this.fileToUpload != null)
  {
    this.valFiletype=this.validateFileType(this.fileToUpload);
    this.valFileSize=this.validateFileSize(this.fileToUpload);
     if(this.valFiletype)
    {
      if(this.valFileSize)
      {
        
       if(this.selectedDocType == "Misc" || this.selectedDocType == "POD/Pickup Confirmation")
       {
        if(this.selectedDocType != "Misc")
        {
          if(this.orderLineCheckedList !== undefined && this.orderLineCheckedList.length !== 0)
          {
      for (var i = 0; i < this.orderLineCheckedList.length; i++) {
        if(this.orderLineCheckedList.length>1)
        {
          if(i== 0)
          this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
          else
          this.conCatLineNumber+=","+this.orderLineCheckedList[i].lineNumber;
        }
      else
      this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
      }
    }
    else
    this.warningFileMsg = "To Upload POD/Pickup Confirmation Document Type please select line Numbers.";
      }
    else
    this.conCatLineNumber = '';
    if(this.warningFileMsg  == "")
    {
  if(this.isAdmin == 'Y')
    this.source = 'CSA';
    else
    this.source = 'Dealer';
  this.orderDtlDealerService.insertUploadedDocs(this.fileToUpload,this.conCatLineNumber,this.ord,this.headerId,this.id,this.desc,this.selectedDocType,this.source,"",this.orderLineNumberValue).subscribe(data => {
    // do something, if upload success
    //this.fileMsg = data.data;
   // alert("Insert");
   this.commonFileErrMsg = "";
   this.warningFileMsg = "";
   this.warningFileDocMsg = "";
   this.fileMsg = "";
   this.fileMainMsg = "";
    if(data.data == "Success")
    {
      this.fileMsg = "Successfully Uploaded";
      this.warningFileMsg = "";
      this.warningFileDocMsg = "";
      this.desc ="";
      $("#noRecUploadMsg").empty();
      $("#customFile").val(''); 
      this.clearFileDtls('U');
      this.fileToUpload = null;
      this.orderDtlDealerService.getUploadedDocs(this.getFileAttParam(this.ord)).subscribe(data => {
        this.selectedUploadedOrder = data.data;
        this.docTypebtn = false;
        this.selectedDocType ='';
        this.orderLineNumberValue = '';
        this.orderLineCheckedList =[];
         console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
      },
      (error) =>
      {
         this.commonFileErrMsg = "";
         this.fileMsg = "";
         this.fileMainMsg = "";
         this.warningFileMsg = "";
         this.warningFileDocMsg = "";
         console.log("Error: "+error);
         this.commonFileErrMsg =error;
      });
    }
    },
    (error) =>
    {
       this.commonFileErrMsg = "";
       this.fileMsg = "";
       this.fileMainMsg = "";
       this.warningFileMsg = "";
       this.warningFileDocMsg = "";
       console.log("Error: "+error);
       this.commonFileErrMsg =error;
    });
  }
}
    else
    {
      this.warningFileMsg = "";
      this.warningFileDocMsg = "";
      this.warningFileMsg="Please select Document Type and upload file";      
    }
  }
    else
    {
      this.warningFileMsg = "";
      this.warningFileMsg="Please select Files size less than or equal to 2MB";
      this.fileToUpload = null;
    }
  }
  
    else
    {
      this.warningFileMsg = "";
      this.warningFileDocMsg = "";
      //this.warningFileMsg="Please Upload Files with  file types .pdf,.xls,.txt,.doc,.docx,.xlsx";
      this.warningFileDocMsg = "Please Upload Files with  file types ";
      this.fileToUpload = null;
    }
  }
  else
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.warningFileMsg="Please Upload the file and click Upload";
    this.fileToUpload = null;
  }
  }

  formatLbl(str:any){
    let s:string="";
    try{
      if(str){
        s=str.toString();
        s=s.replace("CSA","Canon");
        str=s;
       }
    }catch(error){
       console.log(error);
     }
   
    return s;
  }

  }


