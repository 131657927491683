import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
const httpOptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class OrderApprovalsService {

  
  defaultUrl : String;

  constructor(private http: HttpClient,private util : Util) { 
    this.defaultUrl = util.getBaseUrl();
  }


  //for DEALER

  getApprovalDealerList(inputParams):Observable<any>{
    inputParams = JSON.stringify(inputParams);
    let initialUrl = this.defaultUrl + "approvalSearch/gal";
    console.log("inputParams ",inputParams);
    return this.http.post(initialUrl,inputParams,httpOptions)
            .pipe(catchError(this.handleError("getApprovalDealerList")));
  }


  getCounterComp(inputParams):Observable<any>{
    inputParams = JSON.stringify(inputParams);
    let initialUrl = this.defaultUrl + "approvalSearch/gccd";
    console.log("inputParams ",inputParams);
    return this.http.post(initialUrl,inputParams,httpOptions)
            .pipe(catchError(this.handleError("getCounterComp")));
  }

  insertCounterComp(inputParams,respondedBy:string,versionNumber:number,csaemailId:string,bounceemailId:string,dealerEmail:string,shipToCust:string,dealerName:string):Observable<any>{
    inputParams = JSON.stringify(inputParams);
    console.log("insertCounterComp");
    let initialUrl = this.defaultUrl + "approvalSearch/inccd?respondedBy="+respondedBy+"&versionNumber="+versionNumber+"&csaemailId="+csaemailId+"&bounceemailId="+bounceemailId+"&dealerEmail="+dealerEmail+"&shipToCust="+shipToCust+"&dealerName="+dealerName;
    //console.log("inputParams ",inputParams);
    console.log("dealerEmail ",dealerEmail);
    console.log("csaemailId ",csaemailId);
    console.log("bounceemailId ",bounceemailId);

    return this.http.post(initialUrl,inputParams,httpOptions)
            .pipe(catchError(this.handleError("insertCounterComp")));
  }
  //for CSA

  getApprovalCsaList(inputParams):Observable<any>{
    inputParams = JSON.stringify(inputParams);
    let initialUrl = this.defaultUrl + "approvalSearch/gal";
    console.log("inputParams ",inputParams);
    return this.http.post(initialUrl,inputParams,httpOptions)
            .pipe(catchError(this.handleError("getApprovalCsaList")));
  }
  getUploadedDocs(data): Observable<any> {
    //data = JSON.stringify(data);
    //let initialUrl = this.defaultUrlNew+"ordersearch/goal?orderNumber="+id;
   let initialUrl = this.defaultUrl+"ordersearch/goal"

   // let initialUrl = this.defaultUrl2;
   //console.log("in getOrderCsaList."+initialUrl);
   return this.http
   .post(initialUrl,data,httpOptions)
     .pipe(catchError(this.handleError("getUploadedDocs")))
     // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
     //.pipe(map(data => this.toColleciton2(data)));
 }

 getApprovalHist(approvalId:string): Observable<any> {
  let initialUrl = this.defaultUrl+"approvalSearch/gah?approvalId="+approvalId;
   console.log("in approvalId."+approvalId);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("getApprovalHist")))
}

getShipToCnaCode(ittNumber:string,reqNumber:string,cnaShipcode:string): Observable<any> {
  let initialUrl = this.defaultUrl+"approvalSearch/gcsc?ittNumber="+ittNumber+"&reqNumber="+reqNumber+"&cnaShipcode="+cnaShipcode;
  // console.log("in getDelPickupHist."+initialUrl);
   console.log("in ittNumber."+ittNumber);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("getShipToCnaCode")))
}

getDefaultShipToCnaCode(requestId:string,approvalId:string): Observable<any> {
  let initialUrl = this.defaultUrl+"approvalSearch/gdscc?requestId="+requestId+"&approvalId="+approvalId;
  // console.log("in getDelPickupHist."+initialUrl);
   console.log("in approvalId."+approvalId);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("getDefaultShipToCnaCode")))
}

getApprovalNotes(ittNumber:string,reqNumber:string,approvalId:string): Observable<any> {
  let initialUrl = this.defaultUrl+"approvalSearch/gan?ittNumber="+ittNumber+"&reqNumber="+reqNumber+"&approvalId="+approvalId;
  // console.log("in getDelPickupHist."+initialUrl);
   console.log("in ittNumber."+ittNumber);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("getShipToCnaCode")))
}

approveRequest(ittNumber:string,reqNumber:string,approvalId:string,actionType:string,actionBy:string,cnaCode:string,comments:string,csaemailId:string,bounceemailId:string,dealerEmail:string,shipToCust:string,address:string,city:string,state:string,zipcode:string,dealerName:string,requestId:string,cnaAddress:string): Observable<any> {
  let initialUrl = this.defaultUrl+"approvalSearch/apr?ittNumber="+ittNumber+"&reqNumber="+reqNumber+"&approvalId="+approvalId+"&actionType="+actionType+"&actionBy="+actionBy+"&cnaShipToCode="+cnaCode+"&comments="+comments
  +"&csaemailId="+csaemailId+"&bounceemailId="+bounceemailId+"&dealerEmail="+dealerEmail+"&shipToCust="+shipToCust+"&address="+address+"&city="+city+"&state="+state+"&zipcode="+zipcode+"&dealerName="+dealerName+"&requestId="+requestId
  +"&cnaAddress="+cnaAddress;
  // console.log("in getDelPickupHist."+initialUrl);
   console.log("in ittNumber."+ittNumber);

 return this.http
 .post(initialUrl,httpOptions)
   .pipe(catchError(this.handleError("approveRequest")))
}

sendReminderEmail(ittNumber:string,reqNumber:string,approvalId:string,actionType:string,actionBy:string,shipToCust:string,csaemailId:string,bounceemailId:string,dealerEmail:string,dealerName:string): Observable<any> {
  let initialUrl = this.defaultUrl+"approvalSearch/srem?ittNumber="+ittNumber+"&reqNumber="+reqNumber+"&approvalId="+approvalId+"&actionType="+actionType+"&actionBy="+actionBy+"&shipToCust="+shipToCust
  +"&csaemailId="+csaemailId+"&bounceemailId="+bounceemailId+"&dealerEmail="+dealerEmail+"&dealerName="+dealerName;
  // console.log("in getDelPickupHist."+initialUrl);
   console.log("in ittNumber."+ittNumber);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("approveRequest")))
}
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
    let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        console.log("Error:"+error.message);
        errorMessage = `Error Code: ${error.status}\nMessage:"Some Error occured from backend"`;

      }
      //window.alert(errorMessage);
      return throwError(errorMessage);
    }
  }
}
