
export class SerialMeterRead{
    serialNumber:string;
    model:string;
    bwRead:number;
    totalRead:number;
    readDate:any;
    customerName:string;
    source:string;
    scheduleDate:string;
    scheduleDateWarning:boolean;
    address:string;
    clrFlag:string;

    constructor(){
        this.serialNumber='';
        this.model='';
        this.bwRead=0;
        this.totalRead=0;
        this.readDate='';
        this.customerName='';
        this.source='';
        this.scheduleDateWarning=false;
        this.serialNumber='';
        this.address='';
        this.clrFlag='';
    }
}