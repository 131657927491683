import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ServiceRequestService } from '../../service/service-request-service/service-request.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { Subscription } from 'rxjs';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';


declare var $: any;

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.css']
})
export class ServiceRequestComponent implements OnInit, OnDestroy  {


  csaTab: string ='';
  dealerTab: string ='CSA-tab-active dealerTabs';
  selectedPeriod="";
  startDate="";
  endDate="";
  selectedStartDate: any;
  selectedEndDate: any;
  serialNumber;
  modelNumber;
  selectedStatus = '';
  customerName;
  dSelectAll = true;
  dDealerName = true;
  dSerial = true;
  dModel = true;
  dStatus = true;
  dCanonReq = true;
  dDelerRef = true;
  dCreation = true;
  dCustomerName = true;
  dMachineAdd = true;
  dProblemSum = true;
  dAdditionalInfo = true;
  dNotes = true;
  cSelectAll = true;
  cDealerName = true;
  cSerial = true;
  cModel = true;
  cStatus = true;
  cCanonReq = true;
  cDelerRef = true;
  cCreation = true;
  cCustomerName = true;
  cMachineAdd = true;
  cProblemSum = true;
  cAdditionalInfo = true;
  cNotes = true;
  tableLoader = true;
  dealerNameSrch:string ="";


  period = [
    //{ value: 1, text: 'Status' },
    { value: 1, text: 'Current Month' },
    { value: 2, text: 'Last Month' },
    { value: 3, text: 'Current Year' },
    { value: 4, text: 'Last Year' },

  ];

  status = [
    //{ value: 1, text: 'Status' },
    { value: 1, text: 'Cancelled' },
    { value: 2, text: 'Closed' },
    { value: 3, text: 'Contract Hold' },
    { value: 4, text: 'Credit Hold' },
    { value: 5, text: 'Invoice Pending' },
    { value: 6, text: 'Open' },
    { value: 6, text: 'Relocation Hold' },
    { value: 6, text: 'Reopened' },
    { value: 6, text: 'Waiting' }
  ];

  clearButton() {
    this.dealerNameSrch = "";
    this.serialNumber = "";
    this.modelNumber = '';
    this.customerName = "";
    this.selectedPeriod = "";
    this.selectedStatus = "";
    this.startDate = "";
    this.endDate = "";
    this.selectedStartDate = "";
    this.selectedEndDate = "";
  
  }

  changeDSelectAll() {
    if(this.dSelectAll) {
      this.dDealerName = true;
  this.dSerial = true;
  this.dModel = true;
  this.dStatus = true;
  this.dCanonReq = true;
  this.dDelerRef = true;
  this.dCreation = true;
  this.dCustomerName = true;
  this.dMachineAdd = true;
  this.dProblemSum = true;
  this.dAdditionalInfo = true;
  this.dNotes = true;
     
    }else {
      this.dDealerName = false;
      this .dSerial = false;
      this.dModel = false;
      this.dStatus = false;
      this.dCanonReq = false;
      this.dDelerRef = false;
      this.dCreation = false;
      this.dCustomerName = false;
      this.dMachineAdd = false;
      this.dProblemSum = false;
      this.dAdditionalInfo = false;
      this.dNotes = false;
    } 
  }

  changeCSelectAll() {
    if(this.cSelectAll) {
      this.cDealerName = true;
  this.cSerial = true;
  this.cModel = true;
  this.cStatus = true;
  this.cCanonReq = true;
  this.cDelerRef = true;
  this.cCreation = true;
  this.cCustomerName = true;
  this.cMachineAdd = true;
  this.cProblemSum = true;
  this.cAdditionalInfo = true;
  this.cNotes = true;
     
    }else {
      this.cDealerName = false;
      this.cSerial = false;
      this.cModel = false;
      this.cStatus = false;
      this.cCanonReq = false;
      this.cDelerRef = false;
      this.cCreation = false;
      this.cCustomerName = false;
      this.cMachineAdd = false;
      this.cProblemSum = false;
      this.cAdditionalInfo = false;
      this.cNotes = false;
    } 
  }
  


  changeEvent(event,date){ 
    // Return date object
    let dd = event.getDate();
    let mm = event.getMonth() + 1; //January is 0!
    let yyyy = event.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (date=='sd'){
    this.selectedStartDate =  mm + '/' + dd + '/' + yyyy ; 
    }
    if (date=='ed'){
      this.selectedEndDate =  mm + '/' + dd + '/' + yyyy ; 
      }
    //console.log(event);
   // console.log('dd',dd);
    return;
  }


  setScreen(num)
  {
     
    this.setScreenService.changeMyVar(num);
   
 }

  setTab(event:any){
    console.log(event);
    //this.pageNum = 1;
    if(event == 'dealer'){
     this.pageNum = 1;
     this.dealerTab = 'CSA-tab-active dealerTabs';
     this.csaTab = '';
     this.searchDealerService(this.getDealerSearchParam());
     this.setScreenService.setDealersCSS();
     this.serviceType = "dealer";
     //this.setDealersCSS();

     //this.setPgLinks(10000,1,10,10);
    }else if (event == 'csa') {
     this.pageNum = 1;
     this.csaTab = 'CSA-tab-active';
     this.dealerTab = '';
     this.searchCsaService(this.getCsaSearchParam());
     this.setScreenService.setCSACSS();
     this.serviceType = "csa";
    // this.setCSACSS();
    }

  }


//   setDealersCSS() {
//     $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
//     $(".site-logo").attr("src", "assets/img/ep-dealers-logo.png");
//     $(".csa-page-header-title").hide();
//     //$(".csa-page-header-title").show();
//     $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
//     $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
//     $(".main-top-navbar").css("border-top", "1px solid #fff");
//     $(".fa-ellipsis-v").css("color", "#396f96");
//     $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #1b6d85", "background-color": "#7395ae", "color": "#fff" });

  
//         $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");

//         $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");

//     $(".side-more-icons-wrapper").hover(() =>{
//         $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
//     }, () =>{
//         $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//     });
//     $(".side-menu-button-wrapper i").hover(() => {
//         $(this).css({ "color": "rgb(38, 154, 188)", "border": "3px solid rgb(38, 154, 188)" });
//     }, () =>{
//         $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//     });
//     $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
//     $(".group-switcher").css({ "border": "1px solid #2aabd1", "background-color": "#2286a3" });
//     $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
//     //$(".main-csa-content-wrapper,.csa-page-header-title").show();
//     $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
//     $(".btn-primary").css({ "border-color": "#2aabd1", "background-color": "#2286a3", "color": "#fff" });
//     $(".result-table tr td i").css({ "color": "#2aabd1" });
//     $("html,body").css({ "background-color": "#ddd" });
//     $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({ "color": "#fff" });
//     $(".result-table tr th").css({ "background-color": "#7395ae" });

//     $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
//     $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");

// }

// setCSACSS() {
//   $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/csa-bg.jpg')");
//   $(".site-logo").attr("src", "assets/img/ep-csa-logo.png");
//   $("#main-top-nav-wrapper-clone").css("background", "#555");
//   $("#main-side-nav,#mobile-menu-modal-body").css("background", "#333");
//   $(".main-top-navbar").css("border-top", "1px solid rgb(186, 179, 171)");
//   $(".fa-ellipsis-v").css("color", "rgb(204, 204, 204)");
//   $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #333", "background-color": "#dbd5ce", "color": "#59534d" });

//   $(".side-less-icons-wrapper").hover(() => {
//       $(".side-less-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//   }, () =>{
//       $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
//   });
//   $(".side-more-icons-wrapper").hover(() => {
//       $(".side-more-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//   }, () => {
//       $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//   });

//   $(".side-menu-button-wrapper i").hover(() => {
//       $(this).css({ "color": "#dbd5ce", "border": "3px solid #dbd5ce" });
//   }, () =>{
//       $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//   });
//   $(".tooltip .tooltip-inner").css("border-left", "7px solid #dbd5ce");
//   $(".group-switcher").css({ "border": "1px solid #969089", "background-color": "#999" });
//   $(".side-menu-wrapper-inner").css("border-top", "1px solid #ccc");
//   $(".main-dealers-content-wrapper,.dealer-page-header-title").hide();
//   $(".main-csa-content-wrapper,.csa-page-header-title").fadeIn();
//   $(".btn-primary").css({ "border-color": "#969089", "background-color": "#999", "color": "#fff" });
//   $(".result-table tr td i").css({ "color": "#333", "border": "solid 1px #dbd5ce" });
//   $("html,body").css({ "background-color": "#ebe7e4" });
//   $(".main-top-navbar-left-content,.main-top-navbar-links").css({ "color": "#fff" });
//   $(".group-switcher-inner").css({ "color": "#fff" });
//   $(".result-table tr th").css({ "background-color": "#333" });
//   $('.sm-switcher .group-switcher-inner').css("margin-left", "5px");
//   $('.md-switcher .group-switcher-inner').css("margin-left", "0");

// }

loggedUserName=''; 
loggedUserFullName='';
dealerName;
dealerServiceList = [];
csaServiceList = [];
totalRecordsDealer;
totalDealerOrders: Array<number>;
totalDealerPagers;
totalRecordsCsa;
totalCsaOrders: Array<number>;
totalCsaPagers;
selectedSortBy;
selectedSortOrder;
pageNum = 1;
recPerPage = 10;
serviceType;
isAdmin : any;


calTotalPages (pages){
  let totalPages = pages/10;
  let roundedTotalPages = Math.round(totalPages);

  
  if (totalPages > roundedTotalPages) {
    totalPages = roundedTotalPages + 1;
  }else {
    totalPages = roundedTotalPages;
  }

  console.log('totalPages',totalPages);
  return totalPages;
 }


 getDealerSearchParam () {
  
  if(this.startDate) {
    this.changeEvent(this.startDate,'sd');
  }else{
    this.selectedStartDate = "";
  }

  if(this.endDate) {
    this.changeEvent(this.endDate,'ed');
  }else{
    this.selectedEndDate = "";
  }

return {
    "dealerName":this.dealerNameSrch,
    "period" : this.selectedPeriod,
    "startDate": this.selectedStartDate,
    "endDate": this.selectedEndDate,
    "serialNumber" : this.serialNumber,
    "model" : this.modelNumber,
    "customerName" : this.customerName,
    "status" :this.selectedStatus,
    "pageNum": this.pageNum,
    "recPerPage":this.recPerPage,
    "sortBy": this.selectedSortBy,
    "sortOrder": this.selectedSortOrder
  }
  
}

getCsaSearchParam () {

  if(this.startDate) {
    this.changeEvent(this.startDate,'sd');
  }else{
    this.selectedStartDate = "";
  }

  if(this.endDate) {
    this.changeEvent(this.endDate,'ed');
  }else{
    this.selectedEndDate = "";
  }

  return {
    "dealerName":this.dealerNameSrch,
    "period" : this.selectedPeriod,
    "startDate": this.selectedStartDate,
    "endDate": this.selectedEndDate,
    "serialNumber" : this.serialNumber,
    "model" : this.modelNumber,
    "customerName" : this.customerName,
    "status" :this.selectedStatus,
    "pageNum": this.pageNum,
    "recPerPage":this.recPerPage,
    "sortBy": this.selectedSortBy,
    "sortOrder": this.selectedSortOrder
}

  }


  setSortOrder(sortBy,sortOrder,type) {
    this.selectedSortBy = sortBy;
    this.selectedSortOrder = sortOrder;
    if(type=="dealer"){
    this.searchDealerList();    
    }else {
      this.searchCsaList();
    }
  
  }
  
  
  setPage (param,i, event: any) {
    event.preventDefault();
    this.pageNum= i;
    if(param=='dealer'){
     this.searchDealerList();
   }else{
   this.searchCsaList();
   }
  
  }
  
  setNextPage (param,event: any) {
   event.preventDefault();
   this.pageNum++;
   if(param=='dealer'){
     this.searchDealerList();
   }else{
   this.searchCsaList();
   }
  
  
  }
  setPrevPage (param,event: any) {
   event.preventDefault();
   if (this.pageNum > 1){
   this.pageNum--;
  }
  if(param=='dealer'){
   this.searchDealerList();
  }else{
  this.searchCsaList();
  }
  
  
  }
  
  setFirstPage (param:string,event: any) {
   event.preventDefault();
   this.pageNum = 1;
   if(param=='dealer'){
     this.searchDealerList();
   }else{
   this.searchCsaList();
   }
  
  }
  
  setLastPage (param,i,event: any) {
   event.preventDefault();
   this.pageNum = i;
   if(param=='dealer'){
     this.searchDealerList();
   }else{
   this.searchCsaList();
   }
  
  
  }



  dowloadDealer() {
    let totalDealerServiceList = [];
    const dPageNumber = this.pageNum;
    this.recPerPage = 10000;
    this.pageNum = 1;
      this.serviceRequestService.getServiceDealerList(this.getDealerSearchParam()).subscribe(data => {
      totalDealerServiceList = data.data;
      this.serviceRequestService.jsonToExcel(totalDealerServiceList,'Dealer Service', true);
      this.recPerPage = 10;
      this.pageNum = dPageNumber;
    
    });
  
  }
  
  
  dowloadCsa() {
    let totalCsaServiceList = [];
    const cPageNumber = this.pageNum;
    this.recPerPage = 10000;
    this.pageNum = 1;
      this.serviceRequestService.getServiceCsaList(this.getCsaSearchParam()).subscribe(data => {
      totalCsaServiceList = data.data;
      this.serviceRequestService.jsonToExcel(totalCsaServiceList,'Canon Service', true)
      this.recPerPage = 10;
      this.pageNum = cPageNumber;
    
    });
   
  }




  //additionalInfo:AdditionalInfo[]=[];
  additionalInfo=[];
  equipmentList = [];
  eqiTable = "";
  

  getAdditionalInfo (ai,type){

   let params = { 
      "serialNumber" : ai.serialNumber,

    }
    

    this.additionalInfo = ai;

    console.log('this.additionalInfo',this.additionalInfo);

    
    if (type == 'dealer') {
      this.eqiTable = "dealerTable";

      this.serviceRequestService.getServiceEquipmentDealerList(params).subscribe(data => {
        this.equipmentList = data.data;
        console.log('this.equipmentList',this.equipmentList);
      
      });

    }

    if (type == 'csa') {
      this.eqiTable = "csaTable";

      this.serviceRequestService.getServiceEquipmentCsaList(params).subscribe(data => {
        this.equipmentList = data.data;
      
      });

    }



  }

  serviceNotes =[];

  getNotes(nt) { 

    let params = { 
        //"userName" : "CITSMO27AC@SADP.COM",
        "serviceReqNum" : nt.canonRequest,
        "pageNum": "1",
        "recPerPage":"10000"  
      }


    this.serviceRequestService.getServiceNotes(params).subscribe(data => {
    this.serviceNotes = data.data;
    console.log('serviceNotes....',this.serviceNotes);

  
  });
  }
  


  searchDealerList() {
    this.searchDealerService(this.getDealerSearchParam());
    
   }
  
   searchCsaList(){
    this.searchCsaService(this.getCsaSearchParam());
    
   }
  
   searchDealerServiceListBtn() {
    //this.setFilteredClosedOrders();
    this.pageNum = 1;
    this.searchDealerService(this.getDealerSearchParam());
  
   }
  
   searchCsaServiceListBtn(){
    //this.setFilteredClosedOrders();
    this.pageNum = 1;
    this.searchCsaService(this.getCsaSearchParam());
  
   }


  

 searchDealerService(params) { 

  this.tableLoader = true;

  this.serviceRequestService.getServiceDealerList(params).subscribe(data => {
  this.dealerServiceList = data.data;
  let totalDealerPages = data.recCount;
  //this.dealerName = data.dealerName;
  this.totalRecordsDealer = totalDealerPages;
  totalDealerPages = this.calTotalPages(totalDealerPages);
  this.totalDealerPagers = totalDealerPages;

  //this.totalDealerOrders = new Array(data['recCount']);
  this.totalDealerOrders = new Array(totalDealerPages);
  console.log('dealerFleetList list',this.dealerServiceList);
  console.log('totalRecordsDealer',this.totalRecordsDealer);
  this.tableLoader = false;
  //console.log('totalRecordsDealer ',this.totalRecordsDealer );
  //console.log(JSON.stringify(this.csaOrderList));

});
}

searchCsaService(params) { 

  this.tableLoader = true;

  this.serviceRequestService.getServiceCsaList(params).subscribe(data => {
  this.csaServiceList = data.data;
  let totalCsaPages = data.recCount;
  //this.dealerName = data.dealerName;
  this.totalRecordsCsa = totalCsaPages;
  totalCsaPages = this.calTotalPages(totalCsaPages);
  this.totalCsaPagers = totalCsaPages;

  //this.totalDealerOrders = new Array(data['recCount']);
  this.totalCsaOrders = new Array(totalCsaPages);
  console.log('csaFleetList list',this.csaServiceList);
  console.log('totalRecordsCsa',this.totalRecordsCsa);
  this.tableLoader = false;
  //console.log('totalRecordsDealer ',this.totalRecordsDealer );
  //console.log(JSON.stringify(this.csaOrderList));

});
}

pageLoad() {
  // alert("page Load");
    $("#page-loader-overlay")
      .delay(800)
      .fadeOut();
    $("#delay-loader")
      .delay(1000)
      .fadeOut();
    
    }

    private subscription: Subscription;
    myVar;
    srlNo: any;
    pageType: any;


  constructor(private activatedRoute:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private serviceRequestService: ServiceRequestService,private util : Util, private titleService: Title,private setScreenService:SetScreenService) {}


  ngOnInit() {


    this.srlNo = this.activatedRoute
    .queryParams
    .subscribe(params => {
      this.serialNumber = params['sn'];
      this.pageType = params['st'];
      if(this.serialNumber) {
        this.selectedSortBy = 'CREATION_DATE';
        this.selectedSortOrder = 'DESC';

      }
    });
    
    this.subscription = this.setScreenService.changeVar.subscribe(message => {

      //if (message !== this.myVar) {
        //alert("service message"+message);
          this.myVar = Number(message);
          //alert("this.myVar"+this.myVar);
            
          if(this.myVar == 0){
            this.setTab('csa');

  
            }else if(this.myVar == 1){
              this.setTab('dealer');

            }           
     // }
  });

    this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
    this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();

    let username = this.loggedUserName;
    this.titleService.setTitle("All Service Request");  
    this.isAdmin =	sessionStorage.getItem('Admin');
    this.dealerName = sessionStorage.getItem('DealerName');
    //this.setDealersCSS();
    //this.searchDealerList();
    this.pageLoad();
  



  }

  ngOnDestroy() {
    if (this.subscription) {
    this.subscription.unsubscribe();
    }

  }

}
