import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};
const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  defaultUrl : String;
  
  constructor(private http: HttpClient,private util : Util) { 
    this.defaultUrl = util.getBaseUrl();
  }

  getApprovalList(inputParams):Observable<any>{
    let FN = "[getApprovalList]";

    inputParams = JSON.stringify(inputParams);
    let initialUrl = this.defaultUrl + "dashboard/gal";
    return this.http.post(initialUrl,inputParams,httpJsonOptions)
            .pipe(catchError(this.handleError(FN)));
            
  }

  getSRList(inputParams):Observable<any>{
    let FN = "[getSRList]";
    let urlGSRL = this.defaultUrl + "dashboard/gsrl";
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }

  
    return this.http
      .post(urlGSRL, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
            
  }

  getOpenOrderList(inputParams):Observable<any>{
    let FN = "[getOpenOrderList]";
    let urlGOOL = this.defaultUrl + "dashboard/gool";
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }

    return this.http
      .post(urlGOOL, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
            
  }

  getCompList(inputParams):Observable<any>{
    let FN = "[getCompList]";
    let urlGCL = this.defaultUrl + "dashboard/gcl";
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }

    return this.http
      .post(urlGCL, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
            
  }


  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
