import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
const httpOptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};
const httpFileoptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'multipart/form-data; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class OrderDetailDealerService {
      defaultUrl :string;

	 //defaultUrl = "https://eproforma.s21dev.csa.canon.com/csaEP/";
  
      // this.defaultUrl = ""http://localhost:8070/csaEP/";	
       	
      constructor(private http: HttpClient, private util : Util ) {
        this.defaultUrl = util.getBaseUrl();
      //this.defaultUrl = "https://eproforma.s21dev.csa.canon.com/csaEP/";
        console.log("OrderDetailDealerService url:"+this.defaultUrl);	
      }
     
      
 getDealerDtlList(ittNumber:string,callingType:string,ordNumber:string,sortBy:string,sortOrder:string,approvalId:number): Observable<any> {	
     let initialUrl = this.defaultUrl+"orderDtlDealer/gccl?ittNumber="+ittNumber+"&callingType="+callingType+"&ordNumber="+ordNumber+"&sortOrder="+sortOrder+"&sortBy="+sortBy+"&approvalId="+approvalId;
  //console.log("in getDealerDtlList."+initialUrl);
   console.log("in ittNumber."+ittNumber);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("getDealerDtlList")))
 
}
getGsdComp(ordNumber:string,ittNumber:string,lineId:string): Observable<any> {
  let initialUrl = this.defaultUrl+"orderDtlDealer/ggsd?ittNumber="+ittNumber+"&lineId="+lineId+"&ordNumber="+ordNumber;
   console.log("in getGsdComp."+initialUrl);
   console.log("in ittNumber."+ittNumber);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("getGsdComp")))
}
getDelPickupHist(ordNumber:string,ittNumber:string,lineId:string,headerId:string): Observable<any> {
  let initialUrl = this.defaultUrl+"orderDtlDealer/gdph?ittNumber="+ittNumber+"&lineId="+lineId+"&ordNumber="+ordNumber+"&headerId="+headerId;
  // console.log("in getDelPickupHist."+initialUrl);
   console.log("in ittNumber."+ittNumber);

 return this.http
   .get(initialUrl)
   .pipe(catchError(this.handleError("getDelPickupHist")))
}

    saveDealerContacts(param,ordNumber:string,ittNumber:string,headerId,shipOrgId): Observable<any> {
       let initialUrl = this.defaultUrl+"orderDtlDealer/scdc?ittNumber="+ittNumber+"&orderNumber="+ordNumber+"&headerId="+headerId+"&shipOrgId="+shipOrgId;
       console.log('saveDealerContacts data',param);
           return this.http
           .post(initialUrl,param,httpJsonOptions)
             .pipe(catchError(this.handleError("saveDealerContacts")))
            
         }
          shipmentDealerCheck(param,ordNumber:string,ittNumber:string,headerId,dealerName:string): Observable<any> {
            let initialUrl = this.defaultUrl+"orderDtlDealer/sdcc?ittNumber="+ittNumber+"&orderNumber="+ordNumber+"&headerId="+headerId+"&dealerName="+dealerName;          
            //param = JSON.stringify(param);
            console.log('shipmentDealerCheck data',param);
                     return this.http
                     .post(initialUrl,param)
                       .pipe(catchError(this.handleError("shipmentDealerCheck")))
                      
            }
            saveshipmentDealer(param,ordNumber:string,ittNumber:string,headerId,dealerName:string): Observable<any> {
              let initialUrl = this.defaultUrl+"orderDtlDealer/sadc?ittNumber="+ittNumber+"&orderNumber="+ordNumber+"&headerId="+headerId+"&dealerName="+dealerName;          
              //param = JSON.stringify(param);
              console.log('shipmentDealerCheck data',param);
                       return this.http
                       .post(initialUrl,param)
                         .pipe(catchError(this.handleError("saveshipmentDealer")))
                        
              }
            confirmDealerShipments(param,ordNumber:string,ittNumber:string,headerId,dealerName:string,customerName:string): Observable<any> {
              let initialUrl = this.defaultUrl+"orderDtlDealer/csd?ittNumber="+ittNumber+"&orderNumber="+ordNumber+"&headerId="+headerId+"&dealerName="+dealerName+"&customerName="+customerName;          
              //param = JSON.stringify(param);
              console.log('confirmDealerShipments data',param);
                       return this.http
                       .post(initialUrl,param)
                         .pipe(catchError(this.handleError("confirmDealerShipments")))
                                    
              }
              saveNotesDetails(ordNumber:string,ittNumber:string,headerId,dealerName:string,customerName:string,comments:string,username:string): Observable<any> {
                let initialUrl = this.defaultUrl+"orderDtlDealer/snd?ittNumber="+ittNumber+"&orderNumber="+ordNumber+"&headerId="+headerId+"&dealerName="+dealerName+"&customerName="+customerName+"&comments="+comments+"&username="+username;          
                //param = JSON.stringify(param);
                console.log('saveNotesDetails data');
                         return this.http
                         .post(initialUrl,httpJsonOptions)
                           .pipe(catchError(this.handleError("saveNotesDetails")))
                                      
                }
         getUploadedDocs(data): Observable<any> {
          data = JSON.stringify(data);
          //let initialUrl = this.defaultUrl+"dealer/gdol";
          let initialUrl = this.defaultUrl+"orderDtlDealer/goal";
         //console.log("in getOrderCsaList."+initialUrl);
         return this.http
         .post(initialUrl,data,httpJsonOptions)
           .pipe(catchError(this.handleError("getUploadedDocs")))
       }

       getReasonCodeList(data): Observable<any> {
        data = JSON.stringify(data);
        //let initialUrl = this.defaultUrl+"dealer/gdol";
        let initialUrl = this.defaultUrl+"orderDtlDealer/grcl";
       //console.log("in getOrderCsaList."+initialUrl);
       return this.http
       .post(initialUrl,data,httpJsonOptions)
         .pipe(catchError(this.handleError("getReasonCodeList")))
     }

      
       
       deleteUploadedDoc(attachId:string,ordNumber:string,ittNumber:string): Observable<any> {
        //data = JSON.stringify(data);
        //let initialUrl = this.defaultUrl+"dealer/gdol";
        let initialUrl = this.defaultUrl+"orderDtlDealer/del?attachmentId="+attachId+"&ordNumber="+ordNumber+"&ittNumber="+ittNumber;
       //console.log("in getOrderCsaList."+initialUrl);
       return this.http
       .get(initialUrl)
         .pipe(catchError(this.handleError("deleteUploadedDoc")))
     }
     insertUploadedDocs(fileToUpload: File,lineId:string,ordNumber:string,headerId:string,ittNumber:string,desc:string,docType:string,source:string,requestId:string,docTypeString:string): Observable<any> {
      let initialUrl = this.defaultUrl+"orderDtlDealer/ia?lineId="+lineId+"&ordNumber="+ordNumber+"&headerId="+headerId+"&ittNumber="+ittNumber+"&desc="+desc+"&docType="+docType+"&source="+source+"&requestId="+requestId+"&docTypeString="+docTypeString;
     // let initialUrl = this.defaultUrl+"orderDtlDealer/ia";
      const formData: FormData = new FormData();
      formData.append('fileKey', fileToUpload, fileToUpload.name);
      return this.http
        .post(initialUrl, formData)
        .pipe(catchError(this.handleError("insertUploadedDocs")))
  }
    getOrderDtlHeaderSec(ordNumber:string,ittNumber:string): Observable<any> {
      let initialUrl = this.defaultUrl+"orderDtlDealer/godhs?ittNumber="+ittNumber+"&ordNumber="+ordNumber;
       console.log("in getOrderDtlHeaderSec."+initialUrl);
       console.log("in ittNumber."+ittNumber);
    
     return this.http
       .get(initialUrl)
       .pipe(catchError(this.handleError("getOrderDtlHeaderSec")))
    }
    getSearchLineAttachments(data): Observable<any> {
      //  data = JSON.stringify(data);
        //let initialUrl = this.defaultUrlNew+"ordersearch/goal?orderNumber="+id;
       let initialUrl = this.defaultUrl+"orderDtlDealer/gsal"
       return this.http
       .post(initialUrl,data,httpJsonOptions)
         .pipe(catchError(this.handleError("getSearchLineAttachments")))
     }
private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
    //   // TODO: send the error to remote logging infrastructure
    //   console.error(error); // log to console instead

    //   // TODO: better job of transforming error for user consumption
    //   console.log(`${operation} failed: ${error.message}`);

    //   // Let the app keep running by returning an empty result.
    //   return of(result as T);
    let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        console.log("Error:"+error.message);
        errorMessage = `Error Code: ${error.status}\nMessage:"Some Error occured from backend"`;

      }
      //window.alert(errorMessage);
      return throwError(errorMessage);
    }
  }

  // private handleError(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     // client-side error
  //     errorMessage = `Error: ${error.error.message}`;
  //   } else {
  //     // server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   window.alert(errorMessage);
  //   return throwError(errorMessage);
  // }
}
