import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};
const httpFileoptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'multipart/form-data; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class OrderDetailCsaService {

  defaultUrl :string;

  //defaultUrl = "https://eproforma.s21dev.csa.canon.com/csaEP/";
 
     // this.defaultUrl = ""http://localhost:8070/csaEP/";	
        
     constructor(private http: HttpClient, private util : Util ) {
       this.defaultUrl = util.getBaseUrl();
     //this.defaultUrl = "https://eproforma.s21dev.csa.canon.com/csaEP/";
       console.log("OrderDetailCsaService url:"+this.defaultUrl);	
     }
    
     
getCsaDtlList(callingType:string,ordNumber:string): Observable<any> {	
    let initialUrl = this.defaultUrl+"orderDtlCsa/gccl?callingType="+callingType+"&ordNumber="+ordNumber;
  console.log("in getDealerDtlList."+initialUrl);
  console.log("in ordNumber."+ordNumber);

return this.http
  .get(initialUrl)
  .pipe(catchError(this.handleError("getCsaDtlList")))

}
getGsdComp(ordNumber:string,lineId:string): Observable<any> {
 let initialUrl = this.defaultUrl+"orderDtlDealer/ggsd?lineId="+lineId+"&ordNumber="+ordNumber;
  console.log("in getGsdComp."+initialUrl);
  

return this.http
  .get(initialUrl)
  .pipe(catchError(this.handleError("getGsdComp")))
}
getDelPickupHist(ordNumber:string,lineId:string,headerId:string): Observable<any> {
 let initialUrl = this.defaultUrl+"orderDtlCsa/gdph?lineId="+lineId+"&ordNumber="+ordNumber+"&headerId="+headerId;
  console.log("in getDelPickupHist."+initialUrl);
 

return this.http
  .get(initialUrl)
  .pipe(catchError(this.handleError("getDelPickupHist")))
}

   saveCsaContacts(param,ordNumber:string,headerId,shipOrgId): Observable<any> {
      let initialUrl = this.defaultUrl+"orderDtlDealer/scdc?orderNumber="+ordNumber+"&headerId="+headerId+"&shipOrgId="+shipOrgId;
      console.log('saveCsaContacts data',param);
          return this.http
          .post(initialUrl,param,httpJsonOptions)
            .pipe(catchError(this.handleError("saveCsaContacts")))
           
        }
        saveshipmentCsa(param,ordNumber:string,headerId): Observable<any> {
          let initialUrl = this.defaultUrl+"orderDtlCsa/sadc?orderNumber="+ordNumber+"&headerId="+headerId;          
          //param = JSON.stringify(param);
          console.log('saveshipmentCsa data',param);
                   return this.http
                   .post(initialUrl,param,httpJsonOptions)
                     .pipe(catchError(this.handleError("saveshipmentCsa")))
                    
          }
       
        getUploadedDocs(data): Observable<any> {
         data = JSON.stringify(data);     
         //let initialUrl = this.defaultUrl+"dealer/gdol";
         let initialUrl = this.defaultUrl+"orderDtlCsa/goal";
        //console.log("in getOrderCsaList."+initialUrl);
        return this.http
        .post(initialUrl,data,httpJsonOptions)
          .pipe(catchError(this.handleError("getUploadedDocs")))
      }
      saveNotesDetails(ordNumber:string,ittNumber:string,headerId,dealerName:string,customerName:string,comments:string,username:string): Observable<any> {
        let initialUrl = this.defaultUrl+"orderDtlCsa/snd?ittNumber="+ittNumber+"&orderNumber="+ordNumber+"&headerId="+headerId+"&dealerName="+dealerName+"&customerName="+customerName+"&comments="+comments+"&username="+username;          
        //param = JSON.stringify(param);
        console.log('saveNotesDetails data');
                 return this.http
                 .post(initialUrl,httpJsonOptions)
                   .pipe(catchError(this.handleError("saveNotesDetails")))
                              
        }
      private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
        //   // TODO: send the error to remote logging infrastructure
        //   console.error(error); // log to console instead
    
        //   // TODO: better job of transforming error for user consumption
        //   console.log(`${operation} failed: ${error.message}`);
    
        //   // Let the app keep running by returning an empty result.
        //   return of(result as T);
        let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            console.log("Error:"+error.message);
            errorMessage = `Error Code: ${error.status}\nMessage:"Some Error occured from backend"`;
    
          }
          //window.alert(errorMessage);
          return throwError(errorMessage);
        }
      }
      getOrderDtlHeaderSec(ordNumber:string): Observable<any> {
        let initialUrl = this.defaultUrl+"orderDtlCsa/godhs?ordNumber="+ordNumber;
         console.log("in getOrderDtlHeaderSec."+initialUrl);
         console.log("in ordNumber."+ordNumber);
      
       return this.http
         .get(initialUrl)
         .pipe(catchError(this.handleError("getOrderDtlHeaderSec")))
      }
      
      getSearchLineAttachments(data): Observable<any> {
        //  data = JSON.stringify(data);
          //let initialUrl = this.defaultUrlNew+"ordersearch/goal?orderNumber="+id;
         let initialUrl = this.defaultUrl+"orderDtlCsa/gsal"
         return this.http
         .post(initialUrl,data,httpJsonOptions)
           .pipe(catchError(this.handleError("getSearchLineAttachments")))
       }
       deleteUploadedDoc(attachId:string,ordNumber:string,ittNumber:string,requestNumber:string): Observable<any> {
        //data = JSON.stringify(data);
        //let initialUrl = this.defaultUrl+"dealer/gdol";
        let initialUrl = this.defaultUrl+"orderDtlCsa/del?attachmentId="+attachId+"&ordNumber="+ordNumber+"&ittNumber="+ittNumber+"&requestNumber="+requestNumber;
       //console.log("in getOrderCsaList."+initialUrl);
       return this.http
       .get(initialUrl)
         .pipe(catchError(this.handleError("deleteUploadedDoc")))
     }
     insertUploadedDocs(fileToUpload: File,lineId:string,ordNumber:string,headerId:string,ittNumber:string,desc:string,docType:string,source:string,requestId:string,docTypeString:string): Observable<any> {
      let initialUrl = this.defaultUrl+"orderDtlCsa/ia?lineId="+lineId+"&ordNumber="+ordNumber+"&headerId="+headerId+"&ittNumber="+ittNumber+"&desc="+desc+"&docType="+docType+"&source="+source+"&requestId="+requestId+"&docTypeString="+docTypeString;
     // let initialUrl = this.defaultUrl+"orderDtlDealer/ia";
      const formData: FormData = new FormData();
      formData.append('fileKey', fileToUpload, fileToUpload.name);
      return this.http
        .post(initialUrl, formData)
        .pipe(catchError(this.handleError("insertUploadedDocs")))
  }
}
