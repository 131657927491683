import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router'
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Util } from 'src/app/app.util';
import { EpartnersUser } from 'src/app/common/bean/epatners.user';
const httpJsonOptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'application/json')
};
@Injectable({
  providedIn: 'root'
})

export class LoginAuthenticationService {
  defaultUrl : string;
   userFullName:string ="";
  userName :string ="";
  isloggedIn: boolean;
  userRoles: any;
  dealerName: any ="";
  status: string ="";
  statusFlag: string ="";
  constructor(private http: HttpClient, private router: Router, private util : Util) { 
	  
	  this.defaultUrl = this.util.getBaseUrl();	  
  }

getAuthenticatedUser() {
	this.userName =sessionStorage.getItem('userName');
    return this.userName;
  }
 
  retainDealerOrderInquirySearchParams(params,fromDate,toDate)
  {
    sessionStorage.setItem('orderNum',params.orderNumber.trim());
    sessionStorage.setItem('selectedStatus',params.orderStatus);
    sessionStorage.setItem('selectedState',params.shipToState);
    sessionStorage.setItem('selectedZip',params.shipToPostal.trim());
    sessionStorage.setItem('selectedCity',params.shipToCity.trim());
    sessionStorage.setItem('customerName',params.customerName);
    sessionStorage.setItem('selectedRma',params.rma);
    sessionStorage.setItem('selectedQpDealerName',params.qpDealerName);
    sessionStorage.setItem('selectedCnaShiptoCode',params.cnaShiptoCode);
    sessionStorage.setItem('setfilterClosedOrders',params.filterClosed);
    sessionStorage.setItem('selectedCnaShiptoCode',params.cnaShiptoCode.trim());
    // sessionStorage.setItem('orderDate',params.orderedDate);
    // sessionStorage.setItem('toOrderDate',params.toOrderedDate);
    sessionStorage.setItem('orderDate',fromDate);
    sessionStorage.setItem('toOrderDate',toDate);
  }

  
  retainCsaOrderInquirySearchParams(params,fromDate,toDate)
  {
    sessionStorage.setItem('orderNum',params.orderNumber.trim());
    sessionStorage.setItem('selectedStatus',params.orderStatus);
    sessionStorage.setItem('selectedState',params.shipToState);
    sessionStorage.setItem('selectedZip',params.shipToPostal.trim());
    sessionStorage.setItem('selectedCity',params.shipToCity.trim());
    sessionStorage.setItem('customerName',params.customerName);
    sessionStorage.setItem('selectedRma',params.rma);
    sessionStorage.setItem('selectedQpDealerName',params.qpDealerName);
    sessionStorage.setItem('selectedCnaShiptoCode',params.cnaShiptoCode);
    sessionStorage.setItem('setfilterClosedOrders',params.filterClosed);
    sessionStorage.setItem('selectedCnaShiptoCode',params.cnaShiptoCode.trim());   
    sessionStorage.setItem('referenceNum',params.referenceNumber.trim());
    sessionStorage.setItem('requestNumber',params.requestNumber.trim());
    // sessionStorage.setItem('orderDate',params.orderedDate);
    // sessionStorage.setItem('toOrderDate',params.toOrderedDate);
    sessionStorage.setItem('orderDate',fromDate);
    sessionStorage.setItem('toOrderDate',toDate);
  }
getAuthenticatedUserFullName() {
	this.userFullName =sessionStorage.getItem('userFullName');
    return this.userFullName;
  }
  isUserLoggedIn() {
     let user = sessionStorage.getItem('userName');
     return !(user === null);
   }

  //  isUserLoggedIn(): boolean {
  //   this.checkAuthLoginService().subscribe
  //       (data =>{      
  //         console.log("CheckAuth --> Route Guard Authenticated :" + data.authenticated); 
  //          if(data.authenticated == true)
  //          {   
  //           this.isloggedIn = true;
  //          }
  //         });      
	//  	return this.isloggedIn;
	//  }

  checkAuthLoginService() {
	    let beanUrl = this.defaultUrl + "checkAuth";
	    return this.http.get<AuthResponse>(beanUrl)
	      .pipe(
	        map(
	          data => {	
	        	  this.userName=data.userName;
              this.userFullName=data.userFullName;
              if(data.authenticated == true)
              this.isloggedIn = true;
	            return data;
	          }
	        )
	      );
	  }
	  executeUserSessionTimeoutService()
	  {
		    let beanUrl = this.defaultUrl + "logout";
		    console.log("Inside Session Timeout");
		    return this.http.get(beanUrl)
		      .pipe(
		        map(
		          data => {
                sessionStorage.removeItem('userName');
                sessionStorage.removeItem('userFullName');
                sessionStorage.removeItem('Admin');
                sessionStorage.removeItem('roles');
                sessionStorage.removeItem('DealerName');
             
                sessionStorage.removeItem('orderNum');
                sessionStorage.removeItem('selectedStatus');
                sessionStorage.removeItem('selectedState');
                sessionStorage.removeItem('selectedZip');
                sessionStorage.removeItem('selectedCity');
                sessionStorage.removeItem('customerName');
                sessionStorage.removeItem('selectedRma');
                sessionStorage.removeItem('selectedQpDealerName');
                sessionStorage.removeItem('selectedCnaShiptoCode');
                sessionStorage.removeItem('setfilterClosedOrders');
                sessionStorage.removeItem('selectedCnaShiptoCode');
                sessionStorage.removeItem('referenceNum');
                sessionStorage.removeItem('requestNumber');
                sessionStorage.removeItem('orderDate');
                sessionStorage.removeItem('toOrderDate');
		            return data;
		          }
		        )
		      );
      }
      
    changePasswordService(params) : Observable<any> {      
        let initialUrl = this.defaultUrl+"user/changepassword?resetUserName="+params.resetUserName+"&newPassword="+params.newPassword+"&oldPassword="+params.oldPassword;          

        return this.http.post(initialUrl,params,httpJsonOptions)
        .pipe(catchError(this.handleError("changePasswordService")))
      }
    
	updateContactService(params) : Observable<any> {     
        let initialUrl = this.defaultUrl+"user/updateContactInfo?userName="+params.userName+"&email="+params.email+"&contactName="+params.contactName;          

        return this.http.post(initialUrl,params,httpJsonOptions)
        .pipe(catchError(this.handleError("updateContactService")))
      }
  executeUserlogoutService() {
    let beanUrl = this.defaultUrl + "logout";
     sessionStorage.removeItem('userName');
     sessionStorage.removeItem('userFullName');
    return this.http.get(beanUrl)
      .pipe(
        map(
          data => {
            sessionStorage.removeItem('userName');
            sessionStorage.removeItem('userFullName');
            sessionStorage.removeItem('Admin');
            sessionStorage.removeItem('roles');	
            sessionStorage.removeItem('DealerName');

            sessionStorage.removeItem('orderNum');
            sessionStorage.removeItem('selectedStatus');
            sessionStorage.removeItem('selectedState');
            sessionStorage.removeItem('selectedZip');
            sessionStorage.removeItem('selectedCity');
            sessionStorage.removeItem('customerName');
            sessionStorage.removeItem('selectedRma');
            sessionStorage.removeItem('selectedQpDealerName');
            sessionStorage.removeItem('selectedCnaShiptoCode');
            sessionStorage.removeItem('setfilterClosedOrders');
            sessionStorage.removeItem('selectedCnaShiptoCode');
            sessionStorage.removeItem('referenceNum');
            sessionStorage.removeItem('requestNumber');
            sessionStorage.removeItem('orderDate');
            sessionStorage.removeItem('toOrderDate');
            return data;
          }
        )
      )
  }

  
  executeJDBCAuthenticationService(username, password,authToken) {
    let beanUrl = this.defaultUrl + "login";
    const body = {
      username, password,authToken
    }
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });
    return this.http.post<EpartnersUser>(beanUrl, body, { headers: headers })
      .pipe(
        map(
          data => {
              //this.checkAuthLoginService();
             this.userName=data.userName;
             this.userFullName=data.fullName;
             this.dealerName =data.dealerName;
             sessionStorage.setItem('userName',data.userName);
             sessionStorage.setItem('userFullName',data.fullName);
             sessionStorage.setItem('Admin',data.adminUserFlag);
             sessionStorage.setItem('roles', data.rolesList.toString());
             sessionStorage.setItem('DealerName',data.dealerName);
            return data;
          }
        )
      );
  }
    
    
        getUserRoles(): Observable<any> {
            //data = JSON.stringify(data);
            let initialUrl = this.defaultUrl+"user/gr";
          console.log("in getUserRoles URL",initialUrl); 
          return this.http
            .get(initialUrl)
            //.post(initialUrl,data,httpOptions)
            .pipe(catchError(this.handleError("getUserRoles")));
            // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
            //.pipe(map(data => this.toColleciton2(data)));
        }    

        
        getUserAuthToken(): Observable<any> {
          //data = JSON.stringify(data);
          let initialUrl = this.defaultUrl+"getAuthToken";
        console.log("in getUserAuthToken URL",initialUrl); 
        return this.http
          .get(initialUrl)
          //.post(initialUrl,data,httpOptions)
          .pipe(catchError(this.handleError("getUserRoles")));
          // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
          //.pipe(map(data => this.toColleciton2(data)));
      }    

     validateEpartUser(params) {
	    let validateUrl = this.defaultUrl + "validateEpartUser?userName="+params.userName+"&email="+params.email;
	    return this.http.get(validateUrl)
	      .pipe(
	        map(
	          data => {	
	            return data;
	          }
	        )
	      );
	  }

     forgotPassword(params) {
	    let validateUrl = this.defaultUrl + "forgotPassword?userName="+params.userName;
	    return this.http.get(validateUrl)
	      .pipe(
	        map(
	          data => {	
	            return data;
	          }
	        )
	      );
	  }

    createNewUser(params) {
	    let validateUrl = this.defaultUrl + "createNewUser?userName="+params.userName+"&email="+params.email+"&phone="+params.phone+"&vendorCode="+params.vendorCode+"&companyName="+params.companyName;
	    return this.http.get(validateUrl)
	      .pipe(
	        map(
	          data => {	
	            return data;
	          }
	        )
	      );
	  }
  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}

  export class AuthResponse {

	  constructor(public authenticated: boolean,public userName:string,public userFullName:string) {
    }
    

}

export class ResetResponse {

  constructor(public status:string,public statusFlag:string) {
  }
  

}