import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ServiceRequestService } from '../../service/service-request-service/service-request.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { Subscription } from 'rxjs';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
declare var $: any;


@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit, OnDestroy  {

  private subscription: Subscription;
  myVar;
  serviceDetails: any = [];
  requestNumber: any;
  srlNo: any;
  pageType;
  tableLoader = true;
  startDate :any= "";
  selectedStartTime="";
  selectedWorkedHour = "";
  selectedWorkedMin="";
  enteredTechNotes="";
  enteredMeterReads="";
  enteredTotalMeterReads="";
  partsUsed="";
  confirmStartDate="";
  resolutionCodeList = [];
  selectedResolutionCode = "";
  closedStatus = false;
  confirmDebriefMessage: any;
  confimrSelectedStartTime = "";
  confirmDebriefFlag = false;
  isAdmin: any;

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }


  convertTime24to12(time){
    var timeString = time;
    var hourEnd = timeString.indexOf(":");
    var H = +timeString.substr(0, hourEnd);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    return timeString = h + timeString.substr(hourEnd, 3) + ampm;
}


  getServiceRequestInfo() { 

    // let params = {
    //   "reqNum" : this.requestNumber

    // }
  
  
    //this.tableLoader = true;
    this.serviceRequestService.getServiceDetails(this.requestNumber).subscribe(data => {
    this.serviceDetails = data.data;
    // this.serviceDetails.color_machine_flag = 'N';
    // this.serviceDetails.last_bw= 1000;
    // this.serviceDetails.last_total = 1000;
    // this.serviceDetails.last_read_date = '7/12/2020';
    if(this.serviceDetails){
    this.serviceDetails.requestStatus = data.data.requestStatus;
    }
    //this.tableLoader = false;
  
    console.log('serviceDetails ',this.serviceDetails);

    if (this.serviceDetails) {

      //this.closedStatus = true;
    

      this.selectedWorkedHour = this.serviceDetails.workedHours;
      this.selectedWorkedMin = this.serviceDetails.workedMinutes;
      this.selectedResolutionCode = this.serviceDetails.resolutionCode;
      this.enteredTechNotes = this.serviceDetails.techNotes;
      //this.enteredMeterReads = this.serviceDetails.lastBw;
      //this.enteredTotalMeterReads = this.serviceDetails.lastTotal;
      this.partsUsed = this.serviceDetails.partNotes;
      if(this.serviceDetails.techStartTime){
      this.selectedStartTime = this.convertTime12to24(this.serviceDetails.techStartTime);}
      //this.selectedStartTime = this.toggle24hr(this.serviceDetails.techStartTime,1);
      if(this.serviceDetails.techStartDate){
      this.startDate  = new Date(this.serviceDetails.techStartDate); }
 


    }

    if (this.serviceDetails.requestStatus != 'Open' || this.serviceDetails.closedDate || this.serviceDetails.dealerRef == '') {
      this.closedStatus = true;
    }
  
  
  });

  setTimeout(function(){ 
     
    $(".group-switcher").css({
      "border-color": "#999",
      "background-color": "#999 !important",
      "pointer-events": "none"
  });

  $("#main-top-nav-switcher").css({ 
    "border-color": "#999",
    "background-color": "#999",

});
$("#main-menu-switcher").css({ 
    "border-color": "#999",
    "background-color": "#999",

});


}, 200);

this.serviceRequestService.getResolutionCode().subscribe(data => {
  this.resolutionCodeList = data.data;
  //this.additionalInfo = this.acknowledgementList[0];
  //this.tableLoader = false;

  console.log('resolutionCodeList ',this.resolutionCodeList);


});






  }


  changeDateFormat(event){ 
    // Return date object
    let dd = event.getDate();
    let mm = event.getMonth() + 1; //January is 0!
    let yyyy = event.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    this.confirmStartDate =  mm + '/' + dd + '/' + yyyy ; 

    return;
  }


getParams(){

  return {

	     //"userName":"CITSMO27AC@SADP.COM",
        "requestNum":this.requestNumber,
        "serialNum": this.serviceDetails.serialNum,
        "dealerRef":this.serviceDetails.dealerRef,
        "techStartDate": this.confirmStartDate,
        "techStartTime": this.confimrSelectedStartTime,
        "workedHours":this.selectedWorkedHour,
        "workedMinutes":this.selectedWorkedMin,
        "resolutionCode":this.selectedResolutionCode,
        "totalRead":this.enteredTotalMeterReads,
        "bwRead":this.enteredMeterReads,
        "techNotes":this.enteredTechNotes,
        "partNotes":this.partsUsed
  }

}
msg :any = "";
bSubmitError: boolean = false;

submitDetails(){

  console.log("start date",this.startDate)
  this.confirmDebriefMessage = "";
  let bInvalid: boolean = false;
  let errorMsg: string = '';



  if (this.startDate =="" || this.startDate == null) {
    errorMsg += " \nPlease select valid Start Date ";
    bInvalid = true;
  }

  if (this.selectedStartTime =="") {
    errorMsg += " \nPlease select valid Technician Start Time ";
    bInvalid = true;
  }

  if (this.selectedWorkedHour =="") {
    errorMsg += " \nPlease enter valid Worked Hour ";
  }

  if (this.selectedWorkedMin =="") {
    errorMsg += " \nPlease enter valid Worked Minutes";
    bInvalid = true;
  }

  if (this.selectedResolutionCode =="") {
    errorMsg += " \nPlease select valid Resolution Code ";
    bInvalid = true;
  }

  if (this.enteredTotalMeterReads =="") {
    errorMsg += " \nPlease enter valid Total reads ";
    bInvalid = true;}
   else if (this.enteredTotalMeterReads < this.serviceDetails.lastTotal) {
    errorMsg += " \nTotal entered reading is less than previous meter reading of "+ this.serviceDetails.lastTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" entered on "+ this.serviceDetails.lastReadDate;
    bInvalid = true;
  }

  if (this.enteredMeterReads =="" && this.serviceDetails.colorMachineFlag == 'Y') {
    errorMsg += " \nPlease enter valid B/W reads ";
    bInvalid = true;}
  else if (this.enteredMeterReads < this.serviceDetails.lastBw && this.serviceDetails.colorMachineFlag == 'Y') {
    errorMsg += " \nB/W entered reading is less than previous meter reading of "+ this.serviceDetails.lastBw.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" entered on "+ this.serviceDetails.lastReadDate;
    bInvalid = true;
  }



  if (bInvalid) {
    this.msg = errorMsg;
    // this.msgClass = ['error', 'white-space-pre-line'];
    this.bSubmitError = true;
    return;
  } else {
    this.bSubmitError = false;
    this.msg = "";
  }

  
  
  
  if(this.startDate){
    this.changeDateFormat(this.startDate);
    } else{ this.confirmStartDate = "";}

    if(this.selectedStartTime){
    this.confimrSelectedStartTime = this.convertTime24to12(this.selectedStartTime);
    } else {this.confimrSelectedStartTime = "";}





}



  confirmDetails(){

 
    this.confirmDebriefFlag = true;
    this.confirmDebriefMessage = "";
    let params = this.getParams();

    this.serviceRequestService.confirmDebriefInfo(this.getParams()).subscribe(data => {
      this.confirmDebriefMessage = data;
      console.log("confirmDebriefMessage",this.confirmDebriefMessage);
      
      if(this.confirmDebriefMessage){
      this.confirmDebriefFlag = false;
      }

      if(this.confirmDebriefMessage.returnStatus == 'S'){
      this.getServiceRequestInfo();
      }
  
    });

    //console.log("submit params",params);


  }



constructor(private activatedRoute:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private serviceRequestService: ServiceRequestService,private util : Util, private titleService: Title,private setScreenService:SetScreenService) {}


  ngOnInit() {

    this.isAdmin =	sessionStorage.getItem('Admin');


    this.srlNo = this.activatedRoute
    .params
    .subscribe(params => {
      this.requestNumber = params['rn'];
      this.pageType = params['type'];
    });

    this.titleService.setTitle("Service Request Details");

    if(this.pageType == 'dealer'){
      this.getServiceRequestInfo();
    }else {
      this.getServiceRequestInfo();
      this.setScreenService.setCSACSS();

  
    }

    


  }

  ngOnDestroy() {

  }

}
