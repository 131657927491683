import { Directive, HostListener, ElementRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Directive({
  selector: '[CurrencyField]'
})
export class CurrencyFieldDirective {

 // private regex: RegExp = new RegExp('^[0-9]*$');
  private regex: RegExp = new RegExp(/^[0-9,$.]+(\.[0-9]*){0,1}$/g);  //Decimal Number
  private specialKeys: Array<string> = ['Backspace','ArrowLeft','ArrowRight','Delete','Tab'];
  constructor(private elementRef: ElementRef, private currencyPipe:CurrencyPipe) { }
   
  
  /** 
   * Key board action
   * @param event 
   */
  @HostListener('keydown', ['$event'])onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const inputValue: string = this.elementRef.nativeElement.value.concat(event.key);
    //console.log(event.key);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }

    return;
  }

  /**
   * Copy Paste action 
   * @param event 
   */
  @HostListener('paste', ['$event']) onPaste(event) {
      const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
      if (clipboardData) {
         // const regEx = new RegExp('^[0-9]*$');
          if(!this.regex.test(clipboardData)) {
              event.preventDefault();
          }
      }
      return;
  }

  
  /** 
   * Key board action
   * @param event 
   */
  @HostListener('change', ['$event'])onChange(event) {
     
    let inputValue:string=event.target.value;
    inputValue=inputValue.replace(/\$/g,"");
    inputValue=inputValue.replace(/\,/g,"");
    //console.log("change : "+inputValue);
    if (inputValue ) {
      
     let sDigitsInfo:string="1.2-2";
     let clsName:string="";
     clsName=event.target.className;
     if(clsName&& clsName.indexOf("digits-5")>-1){
      sDigitsInfo="1.2-5";
     }
    
     let formattedVal=  this.currencyPipe.transform(inputValue,'USD','symbol',sDigitsInfo);
     event.target.value=formattedVal;
    // console.log("formattedVal : "+formattedVal);
    }

    return;
  }

}
