import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { Util } from '../../app.util';

const httpOptions = {
    headers: new HttpHeaders({
        
    }).set('Content-Type', 'application/json')
  };

@Injectable({
    providedIn: 'root'
})



export class OrderQueryService {

    defaultUrl1 = 'assets/DealerOrderInquiry.json';
    defaultUrl = 'assets/order-csa.json';
    defaultUrl2 = 'assets/attachment-list.json';
    
    defaultUrlNew : string;

    constructor(private http: HttpClient, private util : Util) {
    	
    	  this.defaultUrlNew = this.util.getBaseUrl();	
    }

    
        getStates() {
            return [
              {name:'Select State', value: ''},
              {name:'Alabama', value: 'AL'},
              {name:'Alaska', value: 'AK'},
              {name:'Arizona', value: 'AZ'},
              {name:'Arkansas', value: 'AR'},
              {name:'California', value: 'CA'},
              {name:'Colorado', value: 'CO'},
              {name:'Connecticut', value: 'CT'},
              {name:'Delaware', value: 'DE'},
              {name:'District Of Columbia', value: 'DC'},
              {name:'Florida', value: 'FL'},
              {name:'Georgia', value: 'GA'},
              {name:'Hawaii', value: 'HI'},
              {name:'Idaho', value: 'ID'},
              {name:'Illinois', value: 'IL'},
              {name:'Indiana', value: 'IN'},
              {name:'Iowa', value: 'IA'},
              {name:'Kansas', value: 'KS'},
              {name:'Kentucky', value: 'KY'},
              {name:'Louisiana', value: 'LA'},
              {name:'Maine', value: 'ME'},
              {name:'Maryland', value: 'MD'},
              {name:'Massachusetts', value: 'MA'},
              {name:'Michigan', value: 'MI'},
              {name:'Minnesota', value: 'MN'},
              {name:'Mississippi', value: 'MS'},
              {name:'Missouri', value: 'MO'},
              {name:'Montana', value: 'MT'},
              {name:'Nebraska', value: 'NE'},
              {name:'Nevada', value: 'NV'},
              {name:'New Hampshire', value: 'NH'},
              {name:'New Jersey', value: 'NJ'},
              {name:'New Mexico', value: 'NM'},
              {name:'New York', value: 'NY'},
              {name:'North Carolina', value: 'NC'},
              {name:'North Dakota', value: 'ND'},
              {name:'Ohio', value: 'OH'},
              {name:'Oklahoma', value: 'OK'},
              {name:'Oregon', value: 'OR'},
              {name:'Pennsylvania', value: 'PA'},
              {name:'Rhode Island', value: 'RI'},
              {name:'South Carolina', value: 'SC'},
              {name:'South Dakota', value: 'SD'},
              {name:'Tennessee', value: 'TN'},
              {name:'Texas', value: 'TX'},
              {name:'Utah', value: 'UT'},
              {name:'Vermont', value: 'VT'},
              {name:'Virginia', value: 'VA'},
              {name:'Washington', value: 'WA'},
              {name:'West Virginia', value: 'WV'},
              {name:'Wisconsin', value: 'WI'},
              {name:'Wyoming', value: 'WY'}
            ];
        }
    
    
        getOrderCsaList(data): Observable<any> {
            data = JSON.stringify(data);
            let initialUrl = this.defaultUrlNew+"csaSearchOrder";
          //console.log("in getOrderCsaList."+initialUrl);
          console.log('csa data',data);
          return this.http
            //.get(initialUrl)
            .post(initialUrl,data,httpOptions)
            .pipe(catchError(this.handleError("getOrderCsaList")))
            // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
            //.pipe(map(data => this.toColleciton2(data)));
        }

        getOrderDealerList(data): Observable<any> {
            data = JSON.stringify(data);
            let initialUrl = this.defaultUrlNew+"ordersearch/gdol";
           //console.log("in getOrderCsaList."+initialUrl);
           console.log('orderList data',data);
           return this.http
           .post(initialUrl,data,httpOptions)
             .pipe(catchError(this.handleError("getOrderDealerList")))
             // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
             //.pipe(map(data => this.toColleciton2(data)));
         }

         getUploadedDocs(data): Observable<any> {
           // data = JSON.stringify(data);
            //let initialUrl = this.defaultUrlNew+"ordersearch/goal?orderNumber="+id;
           let initialUrl = this.defaultUrlNew+"ordersearch/goal"

           // let initialUrl = this.defaultUrl2;
           //console.log("in getOrderCsaList."+initialUrl);
           return this.http
           .post(initialUrl,data,httpOptions)
             .pipe(catchError(this.handleError("getUploadedDocs")))
             // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
             //.pipe(map(data => this.toColleciton2(data)));
         }
         getSearchLineAttachments(data): Observable<any> {
          //  data = JSON.stringify(data);
            //let initialUrl = this.defaultUrlNew+"ordersearch/goal?orderNumber="+id;
           let initialUrl = this.defaultUrlNew+"ordersearch/gsal"
           return this.http
           .post(initialUrl,data,httpOptions)
             .pipe(catchError(this.handleError("getSearchLineAttachments")))
         }
         getOrderInquiryShipToCnaCode(data): Observable<any> {
            let initialUrl = this.defaultUrlNew+"ordersearch/gcsc";
          
           return this.http
           .post(initialUrl,data,httpOptions)
             .pipe(catchError(this.handleError("getOrderInquiryShipToCnaCode")))
          }
         deleteUploadedDoc(attachId:string,ordNumber:string,ittNumber:string,requestNumber:string): Observable<any> {
            //data = JSON.stringify(data);
            //let initialUrl = this.defaultUrl+"dealer/gdol";
            let initialUrl = this.defaultUrlNew+"ordersearch/del?attachmentId="+attachId+"&ordNumber="+ordNumber+"&ittNumber="+ittNumber+"&requestNumber="+requestNumber;
           //console.log("in getOrderCsaList."+initialUrl);
           return this.http
           .get(initialUrl)
             .pipe(catchError(this.handleError("deleteUploadedDoc")))
         }
           insertUploadedDocs(fileToUpload: File,lineId:string,ordNumber:string,headerId:string,ittNumber:string,desc:string,docType:string,source:string,requestId:string,docTypeString:string,requestNumber:string): Observable<any> {
            let initialUrl = this.defaultUrlNew+"ordersearch/ia?lineId="+lineId+"&ordNumber="+ordNumber+"&headerId="+headerId+"&ittNumber="+ittNumber+"&desc="+desc+"&docType="+docType+"&source="+source+"&requestId="+requestId+"&docTypeString="+docTypeString
            +"&requestNumber="+requestNumber;
           // let initialUrl = this.defaultUrl+"orderDtlDealer/ia";
            const formData: FormData = new FormData();
            formData.append('fileKey', fileToUpload, fileToUpload.name);
            return this.http
              .post(initialUrl, formData)
              .pipe(catchError(this.handleError("insertUploadedDocs")))
        }
        private handleError<T>(operation = "operation", result?: T) {
            return (error: any): Observable<T> => {
              // TODO: send the error to remote logging infrastructure
              console.error(error); // log to console instead
        
              // TODO: better job of transforming error for user consumption
              console.log(`${operation} failed: ${error.message}`);
        
              // Let the app keep running by returning an empty result.
              return of(result as T);
            };
          }
    
    
    //     getOrderCsaList(): any[] {
    //     return [
    //         {
    //                 "ITTNumber": "IT-11112222-33",
    //                 "OrderStatus": "In Progress",
    //                 "OrderNumber": "44442222",
    //                 "TotalComp": "238",
    //                 "DateOrdered": "1/29/2020",
    //                 "RMA": "No",
    //                 "CustomerName": "AAR Corp",
    //                 "ShiptoAddress": "100 Ridge Rd",
    //                 "ShipToCity": "Jamesburg",
    //                 "ShipTostate": "NJ",
    //                 "ShipToPostal": "08831",
    //                 "BillToAddress": "200 Ridge Rd",
    //                 "BillToCity": "New Jamesburg",
    //                 "BillToState": "NJ",
    //                 "BillToPostal": "08832"
    //             },
    //             {
    //                 "ITTNumber": "IT-33334444-55",
    //                 "OrderStatus": "Closed",
    //                 "OrderNumber": "66668888",
    //                 "TotalComp": "2020",
    //                 "DateOrdered": "1/15/2020",
    //                 "RMA": "No",
    //                 "CustomerName": "AAR1 Corp",
    //                 "ShiptoAddress": "100 Ridge Rd",
    //                 "ShipToCity": "Jamesburg",
    //                 "ShipTostate": "NJ",
    //                 "ShipToPostal": "08831",
    //                 "BillToAddress": "200 Ridge Rd",
    //                 "BillToCity": "New Jamesburg",
    //                 "BillToState": "NJ",
    //                 "BillToPostal": "08832"
    //             },
    //             {
    //                 "ITTNumber": "IT-99998888-11",
    //                 "OrderStatus": "Closed",
    //                 "OrderNumber": "99991111",
    //                 "TotalComp": "1238",
    //                 "DateOrdered": "2/10/2020",
    //                 "RMA": "No",
    //                 "CustomerName": "AAR2 Corp",
    //                 "ShiptoAddress": "100 Ridge Rd",
    //                 "ShipToCity": "Jamesburg",
    //                 "ShipTostate": "NJ",
    //                 "ShipToPostal": "08831",
    //                 "BillToAddress": "200 Ridge Rd",
    //                 "BillToCity": "New Jamesburg",
    //                 "BillToState": "NJ",
    //                 "BillToPostal": "08832"
    //             }
    //       ]
    // }

   csaOrderLabels=["referenceNumber","requestNumber","orderNumber","orderStatus","customerName","orderAmount","orderedDate","rma",
    "shiptoAddress","shipToCity","shipToState","shipToPostal","billToAddress","billToCity","billToState","billToPostal"];
    
    dealerOrderLabels=["ittNumber","orderStatus","customerName","totalComp","orderedDate","rma",
    "shiptoAddress","shipToCity","shipToState","shipToPostal","billToAddress","billToCity","billToState","billToPostal"];
    
    camel2title(camelCase) {
        return camelCase
         // inject space before the upper case letters
         .replace(/([A-Z])/g, function(match) {
            return " " + match;
         })
         // replace first char with upper case
         .replace(/^./, function(match) {
           return match.toUpperCase();
         });
     }
 
 //showLabel = true;
 
  jsonToExcel(JSONData, outputFileName, ShowLabel) {
              
             //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
             var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
             var CSV = '';    
             //Set Report title in first row or line
             //CSV += ReportTitle + '\r\n\n';
             
              
             var lbls;
             var custArr;
             var bCustom=false;
             if(outputFileName=="Canon Orders"){  
                 lbls= this.csaOrderLabels;
                 bCustom=true;  
             }else if (outputFileName=="Dealer Orders"){  
                 lbls=this.dealerOrderLabels;
                 bCustom=true;
             }else{
                 lbls=arrData[0];
             }
             
             //This condition will generate the Label/Header
             if (ShowLabel) {
                 var row = "";
                 //This loop will extract the label from 1st index of on array
                 if(bCustom){
                     for (var index in lbls) {
                         //Now convert each value to string and comma-seprated
                         var lbl=lbls[index];
                         lbl=this.camel2title(lbl);  
                         row += lbl + ',';
                     }
                 }else{ 
                     for (var index in arrData[0]) {
                         //Now convert each value to string and comma-seprated
                         index=this.camel2title(index);
                         row += index + ',';
                     }
                 }
                 row = row.slice(0, -1);
                 //append Label row with line break
                 if(row.indexOf("Dealer Dba")>-1){
                   row=row.replace("Dealer Dba","Dealer DBA");
                 }
                 if(row.indexOf("Rma")>-1){
                    row=row.replace("Rma","Contains Return");
                  }
                 CSV += row + '\r\n';
             }
             
             if(bCustom){
                 
                     //1st loop is to extract each row
                     for (var i = 0; i < arrData.length; i++) {
                         var row = "";
                     //2nd loop will extract each column and convert it in string comma-seprated
                          var oData=arrData[i];
                         
                         for(const k in lbls){  
                             
                             //Skip "" when '=' is appended for preserving leading zeros
                             var arrDataVal = oData[lbls[k]];
                             //console.log(arrDataVal);
                             //check for null and assign "" string
                             if(null == arrDataVal)
                                 arrDataVal = "";
                             if(!isNaN(arrDataVal))
                                 arrDataVal = arrDataVal.toString();
                             if(arrDataVal.startsWith('=')){
                                 row += arrDataVal + ',';
                                 continue;
                             }
                              
                             row += '"' + arrDataVal + '",';
                          
                     }
                         row.slice(0, row.length - 1);
                         //add a line break after each row
                         CSV += row + '\r\n';
                 }
                 
             }else{
             //1st loop is to extract each row
             for (var i = 0; i < arrData.length; i++) {
                 var row = "";
                 //2nd loop will extract each column and convert it in string comma-seprated
                 for (var index in arrData[i]) {
                     
                     //Skip "" when '=' is appended for preserving leading zeros
                     var arrDataVal = (arrData[i][index]);
                     //check for null and assign "" string
                     if(null == arrDataVal)
                         arrDataVal = "";
                     if(!isNaN(arrDataVal))
                         arrDataVal = arrDataVal.toString();
                     if(arrDataVal.startsWith('=')){
                         row += arrDataVal + ',';
                         continue;
                     }
                     row += '"' + arrDataVal + '",';
                 }
                 row.slice(0, row.length - 1);
                 //add a line break after each row
                 CSV += row + '\r\n';
             } 
             
             }
             if (CSV == '') {        
                 alert("Invalid data");
                 return;
             }
             
            //this trick will generate a temp "a" tag
             var link = document.createElement("a");    
             link.id="lnkDwnldLnk";
 
             //this part will append the anchor tag and remove it after automatic click
             document.body.appendChild(link);
 
             var csv = CSV;  
             var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;'}); 
             
             //Generate a file name
             var fileName =outputFileName;   
             //this will remove the blank-spaces from the title and replace it with an underscore
            // fileName += outputFileName.replace(/ /g," ");
 
             if (navigator.msSaveBlob) { // IE 10+
                 navigator.msSaveBlob(blob, fileName + ".csv")
                     } else {
                         var link = document.createElement("a");
                         if (link.download !== undefined) { // feature detection
                             // Browsers that support HTML5 download attribute
                             var csvUrl = window.URL.createObjectURL(blob);
                             link.setAttribute("href", csvUrl);
                             link.setAttribute("download", fileName + ".csv");
                             link.style.display = "visibility:hidden";
                             document.body.appendChild(link);
                             link.click();
                             document.body.removeChild(link);
                         }           
                     }
         }



}